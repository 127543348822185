import React, {useState, useEffect} from "react";
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Link} from "react-router-dom";
import ReflectionActivities from '../components/reflections';

export default function ReflectionsPage( props: any ) {

	document.title = 'Reflections | Maximize Project';

	useEffect(() => {
		if ( localStorage.getItem('session_id') ) {
			localStorage.removeItem('session_id');
		}
		if ( localStorage.getItem('goal') ) {
			localStorage.removeItem('goal');
		}
	});

	return (
		<div>
			<Row>
				<Col xs={12}>
					<ReflectionActivities />
				</Col>
			</Row>
		</div>
	)
}
