import { get, post } from '../xhr';

export function getTerms() {
	let url = 'user-consent/get-terms';
	return get(url);
}

export function getMyConsents() {
	let url = 'user-consent/list-my-recent';
	return get(url);
}

export function saveMyConsent(request: any) {
	let url = 'user-consent';
	return post(url, request);
}