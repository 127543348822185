import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import { parseISO, format } from 'date-fns';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createObservation, updateObservation, getObservationById } from '../../../_services/observation';
import { getUser } from '../../../_services/user';
import { getStrategies } from '../../../_services/strategy';
import './style.scss';
import StrategyInput from "./strategy-input";

export default function ObservationForm(props: any) {

    type FeatureRating = {
        feature_id: number;
        rating: number;
    }

    type Strategy = {
        strategy_id: number;
        features: FeatureRating[];
    }

    type ObservationDataObject = {
        [key: string]: any;
        teacher_id: string;
        date: string;
        duration: number;
        strategies: Strategy[];
        teacher_strengths: string;
        suggestions: string;
    }


    const navigate = useNavigate();

    const [strategyElements, setStrategyElements] = useState<any>({});
    const [isReady, setIsReady] = useState<boolean>(false);
    const [isModified, setIsModified] = useState<boolean>(false);
    const [keysAreReady, setKeysAreReady] = useState<boolean>(false);
    const [teacher, setTeacher] = useState<any>();
    const [topicError, setTopicError] = useState<boolean>(false);
    const [activeAccordionKeys, setActiveAccordionKeys] = useState<any>(null);

    const [strategies, setStrategies] = useState<any>();

    const [observation, setObservation] = useState<ObservationDataObject>(() => {
        return {
            teacher_id: props.teacherId ? props.teacherId : '',
            date: '',
            duration: 0,
            strategies: [],
            teacher_strengths: '',
            suggestions: ''
        }
    });

    useEffect(() => {
        getStrategyData();
        if (props.observationId !== null) {
            getObservationDetails();
        }
        if (props.teacherId !== null && props.observationId === null) {
            getTeacherDetails();
        }
    }, []);

    useEffect(() => {
        if (strategies !== undefined) {
            buildElements();
        }
        // if ( props.observationId === null ) {
        //     setActiveAccordionKeys([]);
        // }
    }, [strategies]);

    useEffect(() => {
        if (strategies !== undefined) {
            getActiveAccordionKeys();
        }
        if ( props.observationId === null ) {
            setIsModified(false);
        } else {
            setIsModified(true);
        }
    }, [observation]);

    useEffect(() => {
        if (activeAccordionKeys !== null) {
            setKeysAreReady(true);
            buildElements();
        }
    }, [activeAccordionKeys]);

    useEffect(() => {
        if (strategyElements !== undefined && Object.keys(strategyElements).length > 0) {
            setIsReady(true);
            if ( props.observationId === null ) {
                setKeysAreReady(true);
            }
            
        }
    }, [strategyElements]);

    const getStrategyData = () => {
        getStrategies().then(resp => {
            setStrategies(resp.data.data);
        }).catch(err => {
            console.log(err);
        });
    }

    const buildElements = () => {
        let el = strategies.map(function (item: any, index: number) {
            let isChecked = false;
            if (observation.strategies.length > 0) {
                observation.strategies.forEach((strategy: any) => {
                    if (strategy.strategy_id === item.id) {
                        isChecked = true;
                    }
                });
            }
            return (
                <StrategyInput key={index} index={index} observation={observation} strategy={item} onCheckChange={handleCheckChange} onRadioChange={handleRadioChange} isChecked={isChecked} />
            )
        });
        setStrategyElements(el);
    }

    const getActiveAccordionKeys = () => {
        let keys: any = [];
        let el = strategies.map(function (item: any, index: number) {
            let isChecked = false;
            if (observation.strategies.length > 0) {
                observation.strategies.forEach((strategy: any) => {
                    if (strategy.strategy_id === item.id) {
                        keys.push(index.toString());
                    }
                });
            }
        });
        setActiveAccordionKeys(keys);
    }

    const getObservationDetails = () => {
        getObservationById(props.observationId).then(resp => {
            console.log('observation response', resp.data.data);
            populateForm(resp.data.data);
        }).catch(err => {
            //navigate('/users');
            console.log(err);
        });
    }

    const getTeacherDetails = () => {
        getUser(props.teacherId).then(resp => {
            setTeacher(resp.data.data);
        }).catch(err => {
            navigate('/users');
            console.log(err);
        });
    }

    const populateForm = (data: any) => {

        let obsData: ObservationDataObject = {
            teacher_id: data.teacher.id,
            date: data.date,
            duration: parseInt(data.duration),
            strategies: [],
            teacher_strengths: data.teacher_strengths,
            suggestions: data.suggestions,
        }

        data.observation_strategies.forEach((item: any, index: number) => {
            obsData['strategies'].push({
                strategy_id: item.strategy_id,
                features: []
            });
            data.strategy_feature_ratings.forEach((feature: any, i: number) => {
                if (feature.strategy_feature.strategy_id === item.strategy_id) {
                    obsData['strategies'][index]['features'].push({
                        feature_id: feature.strategy_feature_id,
                        rating: feature.rating
                    });
                }

            });
        });

        setTeacher(data.teacher);
        setObservation({ ...observation, ...obsData });
    }


    // ======================================
    // event handlers
    // ======================================

    const handleInput = (event: any) => {
        let data: ObservationDataObject = { ...observation };
        data[event.target.id] = event.target.value;
        setObservation(data);
        //setIsModified(true);
    }

    const handleCheckChange = (id: string, isChecked: boolean) => {
        let data = { ...observation };
        if (isChecked) {
            data.strategies.push({
                strategy_id: parseInt(id),
                features: []
            });
        } else {
            let index = data.strategies.findIndex((item: any) => {
                return item.strategy_id === parseInt(id);
            });
            data.strategies.splice(index, 1);
        }
        setObservation(data);
        //setIsModified(true);
    }

    const handleRadioChange = (event: any, strategyId: number) => {

        let data = { ...observation };

        let strategyIndex = data.strategies.findIndex((item: any) => {
            return item.strategy_id === strategyId;
        });

        let featureIndex = data.strategies[strategyIndex].features.findIndex((item: any) => {
            return item.feature_id === parseInt(event.target.name);
        });

        if (featureIndex === -1) {
            data.strategies[strategyIndex].features.push({
                feature_id: parseInt(event.target.name),
                rating: parseInt(event.target.value)
            });
        } else {
            data.strategies[strategyIndex].features[featureIndex].rating = parseInt(event.target.value);
        }

        setObservation({ ...observation, ...data });
        //setIsModified(true);
    }

    const handleSubmit = (event: any) => {
        event.preventDefault();

        if (props.observationId === null) {
            createObservation(observation).then(resp => {
                navigate('/users/' + teacher.id);
                toast('Observation data saved');
            }).catch(err => {
                toast('Failed to save observation data');
                console.log(err);
            });
        } else {
            updateObservation(props.observationId, observation).then(resp => {
                navigate('/users/' + teacher.id);
                toast('Observation data saved');
            }).catch(err => {
                navigate('/users/' + teacher.id);
                toast('Failed to save observation data');
                console.log(err);
            });
        }

    }

    const reloadPage = (event: any) => {
        window.location.reload();
    }

    const goBack = () => {
        window.history.back();
    }

    return (
        isReady ?
            <Form className="observation-form" id="meeting-form" onSubmit={handleSubmit}>
                <Container>
                    <Row>
                        <Col>
                            <p className="fw-bold"><span className="required">Fields marked with</span> are required.</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-4">
                                <Form.Label className="fw-bold">Teacher</Form.Label>
                                <Form.Control type="text" value={teacher?.first_name + ' ' + teacher?.last_name} disabled />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-4">
                                <Form.Label className="fw-bold required">Date</Form.Label>
                                <Form.Control id="date" type="date" name="date" onChange={handleInput} value={observation.date} required></Form.Control>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-4">
                                <Form.Label className="fw-bold required">Duration in minutes</Form.Label>
                                <Form.Control id="duration" type="number" name="number" onChange={handleInput} value={observation.duration} min="0" max="1000" required></Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <Form.Group className="mb-4">
                                <Form.Label className="fw-bold required">Which strategy is the teacher focusing on for their goal?</Form.Label>
                                {topicError ? <Form.Text className="error">This field is required.</Form.Text> : null}

                                {
                                    keysAreReady ?
                                        <Accordion id="accordion" alwaysOpen defaultActiveKey={activeAccordionKeys}>
                                            {strategyElements}
                                        </Accordion>
                                        :
                                        ''
                                }

                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>

                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-4">
                                <Form.Label className="fw-bold mb-0 required">Teacher strengths:</Form.Label>
                                <Form.Control as="textarea" rows={3} id="teacher_strengths" onChange={handleInput} value={observation.teacher_strengths} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-4">
                                <Form.Label className="fw-bold mb-0 required">Ideas to consider for maximizing positive impact of the strategies:</Form.Label>
                                <Form.Control as="textarea" rows={3} id="suggestions" onChange={handleInput} value={observation.suggestions} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <div className="d-flex justify-content-between">
                        <Button variant="secondary" onClick={goBack}>Cancel</Button>
                        <div className="d-flex">
                            {
                                isModified ?
                                    <Button className="mx-4 d-flex align-items-center" variant="secondary" onClick={reloadPage}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-counterclockwise" viewBox="0 0 16 16">
                                            <path fillRule="evenodd" d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2v1z" />
                                            <path d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466z" />
                                        </svg>
                                        <span className="mx-2">Revert to last saved version</span>
                                    </Button>
                                    :
                                    <></>
                            }
                            <Button variant="primary" type="submit">Save Observation</Button>
                        </div>

                    </div>
                </Container>
            </Form>
            :
            <></>
    )

}