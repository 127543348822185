import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { useNavigate, Link, useParams } from "react-router-dom";
import { format, parseISO, differenceInDays } from 'date-fns';

import { getSurveyInProgressBySurveyId } from '../../_services/survey-in-progress';

import { ReactComponent as GemIcon } from '../../images/icons/diamond.svg';
import { ReactComponent as SunIcon } from '../../images/icons/sun.svg';

export default function OnboardingReflectionPage(props: any) {

    document.title = 'Onboarding Reflection | Maximize Project';

    let { survey_id } = useParams();
    const navigate = useNavigate();

    const [surveyInProgress, setSurveyInProgress] = useState<any>();
    const [count, setCount] = useState<number>(0);

    useEffect(() => {
        if (survey_id) {
            getSurveyInProgressBySurveyId(survey_id)
                .then((response: any) => {
                    console.log(response.data.data);
                    setSurveyInProgress(response.data.data[0]);
                })
                .catch((error: any) => {
                    console.log(error);
                });
        }
    }, [survey_id]);

    useEffect(() => {
        if (surveyInProgress) {
            calculateExpiry(surveyInProgress);
        }
    }, [surveyInProgress]);

    const calculateExpiry = (survey: any) => {
        let start = parseISO(survey.created_at);
        let today = new Date();
        if (differenceInDays(today, start) > -8) {
            setCount(differenceInDays(today, start));
        }
    }

    return (
        <div className="onboarding-page">

            <div>
                <div className="onboarding-reflection">
                    <Row className="justify-content-center">
                        <Col xs={12} xl={6}>
                            <h1>Maximize Foundations</h1>
                            <p>This activity defines educational equity and introduces the 3Rs (recognize, respond, and redress) framework.</p>
                            {surveyInProgress &&
                                <p>
                                    {(count < 6) &&
                                        <span className="text-danger"><strong>You have {7 - count} day{count === 6 ? '' : 's'} to complete this activity, or you will have to start over.</strong></span>
                                    }
                                </p>
                            }
                        </Col>
                    </Row>
                </div>
            </div>

            <div className="action-button-container">
                <Container>
                    <Row className="justify-content-center">
                        <Col xs={12} xl={9}>
                            <div className="d-flex justify-content-between">
                                <Button variant="secondary" onClick={() => navigate('/logout')}>Log Out</Button>
                                <div className="d-flex align-items-center">
                                    <div className="me-4 d-flex align-items-center">
                                        <SunIcon className="me-2" />
                                        <strong>+0.25</strong>
                                    </div>
                                    <Link className="btn btn-primary" role="button" to={'/survey/' + survey_id}>{surveyInProgress ? 'Continue ' : 'Start '} Activity</Link>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

        </div>
    )
}
