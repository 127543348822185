import React, {useState, useEffect} from "react";
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import {Link} from "react-router-dom";
import Connection from './connection';
import {getAllCoachTeacherEntries} from '../../_services/coach-teacher';


export default function MyConnections( props: any ) {

	const handleReload = () => {
		props.handleReload(true);
	}

    const [ results, setResults ] = useState<any>();

    useEffect(() => {
        getConnections();
    }, [props.handleReload]);

    const getConnections = () => {
        getAllCoachTeacherEntries('coach').then( resp => {
            buildResults(resp.data.data);
        }).catch( err => {

        });
    }

	const buildResults = ( data: any ) => {
        let elements = data.map(function (item: any, index: number) {
            return <Connection key={index} result={item} handleReload={handleReload} />
        });
        setResults(elements);
    } 

	return (

            (results && results.length > 0) ? 
                <div className="panel" id="my-connections">
                <div className="panel-header">
                    <h1>My Connections</h1>
                </div>
                <div>
                    <Table striped bordered hover responsive className="results-table">
                        <thead>
                            <tr>
                                <td>ID</td>
                                <td>Name</td>
                                <td>Email</td>
                                <td>Number of logins</td>
                                <td>Active goals</td>
                                <td>Completed goals</td>
                                <td>Last meeting</td>
                                <td>Actions</td>
                            </tr>
                        </thead>
                        <tbody>
                            {results}
                        </tbody>
                    </Table>
                </div>
            </div>
            :
            <></>

        

	)
}