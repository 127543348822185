import React, { useState, useEffect } from 'react';
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useNavigate, Link } from "react-router-dom";
import { parseISO, differenceInDays, differenceInWeeks } from 'date-fns';
import Fireworks from "react-canvas-confetti/dist/presets/fireworks";

import GameboardIcon from './gameboard-icon';
import { handleStepCompletion } from '../../components/user-workflow/user-workflow-controller';
import { getSurveyInProgressBySurveyId } from '../../_services/survey-in-progress';
import { updateGoal } from '../../_services/goal';

import { ReactComponent as GemIcon } from '../../images/icons/diamond.svg';
import { ReactComponent as SunIcon } from '../../images/icons/sun.svg';


export default function GameboardStep(props: any) {

    const [icon, setIcon] = useState<any>();
    const [showModal, setShowModal] = useState(false);
    const [surveyInProgress, setSurveyInProgress] = useState<any>();
    const [count, setCount] = useState<number>(0);
    const [goalDuration, setGoalDuration] = useState<number>(0);

    let userJSON = localStorage.getItem('user');
    let user = userJSON !== null ? JSON.parse(userJSON) : {};
    let isAdmin = user !== null ? user.is_admin : false;

    const [modalContent, setModalContent] = useState<any>({
        title: '',
        progress: '',
        url: ''
    });

    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);

    let navigate = useNavigate();

    useEffect(() => {
        setUpGameboardStep();
    }, []);

    useEffect(() => {
        if (props.goal) {
            calculateGoalDuration();
        }
    }, [props.goal]);

    useEffect(() => {
        if (surveyInProgress) {
            calculateExpiry();
        }
    }, [surveyInProgress]);

    const getSurveyInProgress = (survey_id: string) => {
        getSurveyInProgressBySurveyId(survey_id).then((response: any) => {
            setSurveyInProgress(response.data.data[0]);
        }).catch(error => {
            console.error('error', error);
        });
    }

    const handleStart = () => {
        if (props.step.name === 'Strategy Information') {
            window.open(modalContent.url, '_blank');
            handleStepCompletion({
                workflowName: 'Strategy'
            });
        } else {
            if (surveyInProgress) {
                localStorage.setItem('session_id', surveyInProgress.session_id);
            }
            navigate(modalContent.url, { state: {} });
        }
    }

    const calculateExpiry = () => {
        console.log('survey in progress', surveyInProgress);
        let start = parseISO(surveyInProgress.created_at);
        let today = new Date();
        if (differenceInDays(today, start) > -8) {
            setCount(differenceInDays(today, start));
        }
    }

    const calculateGoalDuration = () => {
        let today = new Date();
        let start = parseISO(props.goal.created_at);
        setGoalDuration(differenceInWeeks(today, start));
    }

    const setUpGameboardStep = () => {
        switch (props.step.name) {
            case 'Strategy Information':
                setModalContent({
                    title: 'Explore ' + props.workflow.strategy.strategy.name,
                    description: 'Learn how to maximize the benefits of this strategy for you and your students.',
                    progress: 'Step ' + props.workflow.current_step + ' of 7',
                    url: 'https://learn.maximizeproject.app/' + props.workflow.strategy.strategy.page_id
                });
                setIcon(<GameboardIcon name={'book'} isComplete={props.workflow.current_step >= props.step.order ? true : false} />);
                break;
            case 'Reflection':
                let nextReflection = props.step.order === 2 ? props.workflow.strategy.strategy.reflections[0].reflection : props.workflow.strategy.strategy.reflections[1].reflection;
                setModalContent({
                    title: nextReflection.name,
                    description: 'Engage in an activity designed to help you reflect on important principles and considerations for this strategy.',
                    progress: 'Step ' + props.workflow.current_step + ' of 6',
                    url: '/survey/' + nextReflection.survey_id
                });
                getSurveyInProgress(nextReflection.survey_id);
                setIcon(<GameboardIcon name={'lightbulb'} isComplete={props.workflow.current_step >= props.step.order ? true : false} />);
                break;
            case 'Goal Builder':
                setModalContent({
                    title: 'Set a Goal',
                    description: 'Set a personalized goal for this strategy!',
                    progress: 'Step ' + props.workflow.current_step + ' of 7',
                    url: '/survey/' + props.workflow.strategy.strategy.wizard_id
                });
                getSurveyInProgress(props.workflow.strategy.strategy.wizard_id);
                setIcon(<GameboardIcon name={'star'} isComplete={props.workflow.current_step >= props.step.order ? true : false} />);
                break;
            case 'Goal Review':
                setModalContent({
                    title: 'Review Your Goal',
                    description: 'Review your progress and make any necessary changes.',
                    progress: 'Step ' + props.workflow.current_step + ' of 7',
                    url: '/survey/' + process.env.REACT_APP_GOAL_REVIEW_SURVEY_ID
                });
                //getSurveyInProgress(process.env.REACT_APP_GOAL_REVIEW_SURVEY_ID!);
                setSurveyInProgress(null);
                setIcon(<GameboardIcon name={'glass'} isComplete={props.workflow.current_step >= props.step.order ? true : false} />);
                break;
            case 'Goal Completion':
                setModalContent({
                    title: 'Complete Your Goal',
                    description: 'Mark your goal as complete and share on your progress.',
                    progress: 'Step ' + props.workflow.current_step + ' of 7',
                    url: '/survey/' + process.env.REACT_APP_GOAL_COMPLETION_SURVEY_ID
                });
                getSurveyInProgress(process.env.REACT_APP_GOAL_COMPLETION_SURVEY_ID!);
                setIcon(<GameboardIcon name={'check'} isComplete={props.workflow.current_step >= props.step.order ? true : false} />);
                break;
            case 'Finish':
                let isComplete = false;
                if (props.workflow.current_step_status == 'complete') {
                    isComplete = true;
                }
                setIcon(<GameboardIcon name={'trophy'} isComplete={isComplete} />);
                setModalContent({
                    title: 'Great Job! You Finished!',
                    description: '',
                    progress: 'All done! You completed all steps!',
                    url: ''
                });
                break;
            default:
                setIcon(<GameboardIcon name={'trophy'} isComplete={false} />);
                setModalContent({
                    title: 'Error',
                    progress: '',
                    url: ''
                });
                break;
        }
    };

    const handleCompletionClick = () => {
        handleStepCompletion({
            workflowName: 'Strategy'
        });
    }

    return (
        <div className="mb-4 gameboard-step-container">

            <div className="gameboard-step" onClick={handleShow}>

                {icon}

                {(props.workflow.current_step === props.step.order && props.workflow.current_step_status !== 'complete')

                    &&

                    <div>

                        <OverlayTrigger
                            trigger="click"
                            key={'left'}
                            placement={'left'}
                            show={true}
                            overlay={
                                <Popover id="you-are-here" hasDoneInitialMeasure={false}>
                                    <Popover.Body>
                                        <strong>You are here!</strong>
                                    </Popover.Body>
                                </Popover>
                            }
                        >
                            <div className="ring"></div>
                        </OverlayTrigger>

                    </div>

                }

            </div>



            <Modal centered show={showModal} onHide={handleClose}>
                {
                    props.step.name === 'Finish' ?
                        <>
                            {
                                props.workflow.current_step_status === 'complete' ?
                                    <>
                                        <Fireworks autorun={{ speed: 3, duration: 600, delay: 500 }} />
                                        <Modal.Body className="gameboard-modal justify-content-center d-flex flex-column align-items-center">
                                            <GameboardIcon name={'trophy'} isComplete={true} />
                                            <h2>All done! Nice work!</h2>
                                            <p className="text-center mb-4">You completed all steps for this strategy! Now it's time to do another self-assessment, and choose a new strategy to focus on.</p>
                                            <Button variant="primary" size="lg" onClick={handleCompletionClick}>Continue</Button>
                                        </Modal.Body>
                                    </>
                                    :
                                    <>
                                        <Modal.Body className="gameboard-modal justify-content-center d-flex flex-column align-items-center">
                                            <GameboardIcon name={'trophy'} isComplete={false} />
                                            <h2>Complete all steps</h2>
                                            <p className="text-center mb-4">Complete all steps of the workflow to unlock.</p>
                                            <Button variant="secondary" onClick={handleClose}>Locked</Button>
                                        </Modal.Body>
                                    </>
                            }

                        </>
                        :
                        <></>
                }
                {props.workflow.current_step === props.step.order &&
                    <>
                        {props.workflow.current_step_status === 'complete' ?
                            <Modal.Body className="gameboard-modal">
                                <h3>{modalContent.title}</h3>
                                <h4>{props.step.name}</h4>
                                <p>You finished this step! Nice work!</p>
                                <Button variant="secondary" onClick={handleClose}>
                                    Completed
                                </Button>
                            </Modal.Body>
                            :
                            <Modal.Body className="gameboard-modal">
                                <h3>
                                    <span>{modalContent.title}</span>
                                    {props.step.name === 'Reflection' ?
                                        <div className="credits d-flex align-items-center">
                                            <SunIcon className="me-2" />
                                            <strong>+0.25</strong>
                                        </div>
                                        :
                                        <></>
                                    }
                                </h3>

                                <h4>{props.step.name} - {modalContent.progress}</h4>
                                <p className="mb-4">{modalContent.description}</p>


                                {surveyInProgress &&
                                    <p className="mb-4">
                                        {(count < 6) &&
                                            <span className="text-danger"><strong>You have {7 - count} day{count === 6 ? '' : 's'} to complete this activity, or you will have to start over.</strong></span>
                                        }
                                    </p>
                                }


                                {props.step.name === 'Goal Completion' ?
                                    <>
                                        {(goalDuration < 4) ?
                                            <>
                                                <p className="text-danger mb-4">Please focus on your goal for <strong>{4 - goalDuration} more week{goalDuration < 3 ? 's' : ''}</strong> to unlock this step.</p>
                                                <Button variant="secondary" onClick={handleClose}>
                                                    Continue working on goal
                                                </Button>
                                                {isAdmin ?
                                                    <>
                                                        <p className="mb-2 mt-4">Admin only:</p>
                                                        <Link className="btn btn-primary" role="button" to={modalContent.url} state={{ 'goal': props.goal }}>
                                                            Complete goal anyway
                                                        </Link>
                                                    </>
                                                    :
                                                    <></>
                                                }

                                            </>
                                            :
                                            <Link className="btn btn-primary" role="button" to={modalContent.url} state={{ 'goal': props.goal }}>
                                                {surveyInProgress ? 'Continue' : 'Get Started'}
                                            </Link>
                                        }
                                    </>
                                    :
                                    <></>
                                }


                                {props.step.name === 'Goal Review' ?
                                    <Link className="btn btn-primary" role="button" to={modalContent.url} state={{ 'goal': props.goal }}>Get Started</Link>
                                    :
                                    <></>
                                }

                                {props.step.name !== 'Goal Review' && props.step.name !== 'Goal Completion' ?
                                    <Button variant="primary" onClick={handleStart}>
                                        {surveyInProgress ? 'Continue' : 'Get Started'}
                                    </Button>
                                    :
                                    <></>
                                }

                            </Modal.Body>
                        }

                    </>
                }

                {props.workflow.current_step < props.step.order &&
                    <Modal.Body className="gameboard-modal">
                        <h3>{modalContent.title}</h3>
                        <h4>{props.step.name}</h4>
                        <p>Complete all steps above to unlock this!</p>
                        <Button variant="secondary" onClick={handleClose}>
                            Locked
                        </Button>
                    </Modal.Body>
                }

                {props.workflow.current_step > props.step.order &&
                    <Modal.Body className="gameboard-modal">
                        <h3>{modalContent.title}</h3>
                        <h4>{props.step.name}</h4>
                        <p>You finished this step! Nice work!</p>
                        <Button variant="secondary" onClick={handleClose}>
                            Completed
                        </Button>
                    </Modal.Body>
                }

            </Modal>

        </div>

    )
}