import React, { useState, useEffect } from "react";
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import { Link } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import { toast } from 'react-toastify';
import { generateReport, indexReports, deleteReport } from '../_services/report';

export default function ReportsPage(props: any) {

    document.title = 'Reports | Maximize Project';

    const [reports, setReports] = useState<any>([]);
    const [searchFilters, setSearchFilters] = useState<any>({
        startDate: '',
        endDate: '',
        school: ''
    });
    const [reportElements, setReportElements] = useState<any>();
    const token = localStorage.getItem('token')!;
    const [isProcessing, setIsProcessing] = useState<boolean>(false);

    useEffect(() => {
        if (localStorage.getItem('session_id')) {
            localStorage.removeItem('session_id');
        }
        if (localStorage.getItem('goal')) {
            localStorage.removeItem('goal');
        }
        loadReportList();
    }, []);

    useEffect(() => {
        buildReportElements();
    }, [reports]);

    const loadReportList = () => {
        indexReports().then(resp => {
            setReports(resp.data);
        }).catch(err => {
            console.log(err);
        });
    }

    const buildReportElements = () => {
        let elements: any = [];
        reports.forEach((report: any, index: number) => {
            elements.push(
                <tr key={index} className="mb-4">
                    <td>
                        <p className="mb-0">{report.name}</p>
                    </td>
                    <td>
                        <p className="mb-0">{report.lastModified}</p>
                    </td>
                    <td>
                        <Button variant="danger" size="sm" id={report.name} onClick={handleDelete} className="me-2 mb-md-2 mb-xl-0">Delete</Button>
                        <a href={process.env.REACT_APP_API_URL + 'data-export/download/' + report.name + '?token=' + token} target="_blank" className="btn btn-primary btn-sm">Download</a>
                    </td>
                </tr>
            );
        });
        setReportElements(elements);
    }

    const handleDelete = (event: any) => {

        if (!window.confirm('Are you sure you want to delete this report?')) {
            return;
        }

        let request = {
            file: event.target.id
        };
        deleteReport(request).then(resp => {
            toast.success('Report deleted.');
            loadReportList();
        }).catch(err => {
            toast.error('There was an error deleting your report. Please try again.');
        });
    }


    const handleTextEntry = (event: any) => {
        let data = {
            [event.target.id]: event.target.value
        };
        setSearchFilters({ ...searchFilters, ...data });
    }

    const handleSubmit = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        setIsProcessing(true);
        generateReport(searchFilters).then(resp => {
            toast.success('Report request received! Please check back in a few minutes.');
            setIsProcessing(false);
        }).catch(err => {
            toast.error('There was an error requesting your report. Please try again.');
            setIsProcessing(false);
        });
    }

    const handleReset = () => {
        setSearchFilters({
            startDate: '',
            endDate: '',
            school: ''
        });
    }

    return (
        <div>
            <Row>
                <Col xs={12}>
                    <h1 className="mb-4">Reports</h1>
                    <Card className="p-4">
                        <Card.Body>
                            <h3>Instructions</h3>
                            <p>Use this form to filter your query. School names must be an exact match to what is in the database. Report generation can take up to 5 minutes. You will receive an email notifying you when the report is ready for download. If it has been more that 20 minutes, please try again. If the issue persists, contact technical support.</p>
                            <Form noValidate onSubmit={handleSubmit}>
                                <Row>
                                    <Col xs={12} lg={6}>
                                        <Form.Group className="mb-4">
                                            <Form.Label>School</Form.Label>
                                            <Form.Control type="text" id="school" value={searchFilters.school} onChange={handleTextEntry} />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} lg={3}>
                                        <Form.Group className="mb-4">
                                            <Form.Label>Start Date</Form.Label>
                                            <Form.Control type="date" id="startDate" value={searchFilters.startDate} onChange={handleTextEntry} />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} lg={3}>
                                        <Form.Group className="mb-4">
                                            <Form.Label>End Date</Form.Label>
                                            <Form.Control type="date" id="endDate" value={searchFilters.endDate} onChange={handleTextEntry} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} lg={12} className="d-flex justify-content-end">
                                        <Button variant="secondary" className="me-2" onClick={handleReset}>Reset Filters</Button>
                                        <Button variant="primary" type="submit" className="m-0">{isProcessing ? 'Processing...' : 'Generate Report'}</Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Card>
                    <Card className="p-4">
                        <Table>
                            <thead>
                                <tr>
                                    <th>Report Name</th>
                                    <th>Date Created</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {reportElements}
                            </tbody>
                        </Table>

                    </Card>
                </Col>
            </Row>
        </div>
    )
}
