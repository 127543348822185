import React, {useState, useEffect} from 'react';
import Choice from './choice';

export default function MultipleChoice( props: any ) {

	const [ isHorizontal, setIsHorizontal ] = useState<boolean>(false);
	const [ isReady, setIsReady ] = useState<boolean>(false);

	const handleChecked = (choice:any) => {
		props.selectedItem(choice);
	}

	var choices = props.choices.map(function (item: any, index: number) {
		return <Choice key={index} choice={item} questionId={props.questionId} isChecked={handleChecked} sequence={index} />;
	});

	useEffect(() => {
		if (!isReady) {
			if ( 
				props.choices[0].display === '0' || 
				props.choices[0].display === '1' ||
				props.choices[0].display === 'Rarely' || 
				props.choices[0].display === 'frequency' ||
				props.choices[0].display === 'Oops! I forgot about it.' ||
				props.choices[0].display === 'Girl' ||
				props.choices[0].display === 'Yes' 
			) {
				setIsHorizontal(true);
			}
			setIsReady(true);
		}
		
	});

	

	return (
		<div className={isHorizontal ? 'horizontal multiple-choice' : 'multiple-choice'}>
			{choices}
		</div>
	)
}