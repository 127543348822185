// NotFoundPage.tsx
import React from 'react';

import { ReactComponent as Logo } from '../images/maximize-logo.svg';

const NotFoundPage = () => {
  return (
    <div className="d-flex flex-column align-items-center justify-content-center vh-100">
      
      <h1>Page Not Found</h1>
      <p>The page you are looking for does not exist.</p>
      <p className="text-secondary mb-4">Error Code: 404</p>
      <a href="/workflow-routing" className="btn btn-primary">Go back to Maximize</a>
    </div>
  );
};

export default NotFoundPage;