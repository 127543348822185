import React, {useState, useEffect} from "react";
import { useLocation, useParams } from 'react-router-dom'

import Survey from '../components/survey';

export default function SurveyPage( props: any ) {

	document.title = 'Loading... | Maximize Project';

	let { surveyId } = useParams();

	const location:any = useLocation();
	const [ goal, setGoal ] = useState<any>(location.state ? location.state.goal : null);

	return (
		<div>
			<Survey surveyId={surveyId} goal={goal} />
		</div>
	)
}