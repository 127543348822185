import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from "react-router-dom";
import { parseISO, format, differenceInDays } from 'date-fns';
import Loader from '../loader';
import { getGoalsByUser } from '../../_services/goal';
import UserGoalCard from './user-goal-card';
import CompletedGoals from "../my-goals/completed-goals";



export default function UserGoals(props: any) {

	const [activeGoals, setActiveGoals] = useState<any>();
	const [completedGoals, setCompletedGoals] = useState<any>();
	const [isReady, setIsReady] = useState<boolean>(false);

	document.title = 'User Profile | Maximize Project';

	useEffect(() => {
		getUserGoals();
	}, []);

	const getUserGoals = () => {
		getGoalsByUser(props.userId).then(resp => {
			console.log('goals', resp.data.data);
			buildElements(resp.data.data);
		}).catch(err => {
			console.log(err);
		});
	}

	const buildElements = (data: any) => {
		let active: any = [];
		let complete: any = [];

		data.forEach((item: any, index: number) => {
			if (!item.is_complete) {
				active.push(<UserGoalCard key={index} goal={item} />);
			}
			if (item.is_complete) {
				complete.push(<UserGoalCard key={index} goal={item} />);
			}
		});

		setActiveGoals(active);
		setCompletedGoals(complete);
		setIsReady(true);
	}

	return (
		<>
			{
				isReady ?
					<div className="user-goals mb-4">
						{
							props.type === 'active' ?
								<>
									{activeGoals.length > 0 ? activeGoals : <div className="panel">This user has no active goals.</div>}
								</>
								:
								<>
									{completedGoals.length > 0 ? completedGoals : <div className="panel">This user has no completed goals.</div>}
								</>
						}

					</div>
					:
					<></>
			}
		</>
	)
}

