import React, { ComponentType } from "react";
import { Navigate, Route, useLocation } from 'react-router-dom';
import LoginPage from '../../pages/login-page';

interface ProtectedRouteProps {
  component: ComponentType;
  page?: string;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  component,
  page,
}) => {

  const Component = component;
  const userJSON = localStorage.getItem('user');
	let user = userJSON !== null ? JSON.parse(userJSON) : {};
	let isAdmin = user !== null ? user.is_admin : false;
  const token = localStorage.getItem('token');
  const agreed_to_terms = userJSON !== null ? JSON.parse(userJSON).agreed_to_terms : false;

  if ( userJSON && token ) {
    // if ( agreed_to_terms ) {

    //   if ( page === 'terms' ) {
    //     return <Navigate to="/workflow-routing" />;
    //   }

    //   if ( page === 'reports' && !isAdmin ) {
    //     return <Navigate to="/workflow-routing" />;
    //   } else {
    //     return <Component />;
    //   }
      
    // } else {
    //   if ( page === 'terms' ) {
    //     return <Component />;
    //   } else {
    //     return <Navigate to="/agree-to-terms" />;
    //   }
    // }



    if ( page === 'reports' && !isAdmin ) {
      return <Navigate to="/workflow-routing" />;
    } else {
      return <Component />;
    }


  } else {
    return <Navigate to="/login" />;
  }

};
