import React, {useState, useEffect} from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import {deleteEntry} from '../../_services/coach-teacher';
import {getGoalsByUser} from '../../_services/goal';

export default function Connection( props: any ) {

    const [ showModal, setShowModal ] = useState<boolean>(false);
    const [ activeGoals, setActiveGoals ] = useState<number>(0);
    const [ completedGoals, setCompletedGoals ] = useState<number>(0);

    useEffect(() => {
        getGoalDetails();
    }, []);

    const getGoalDetails = () => {
        getGoalsByUser( props.result.teacher.id ).then( resp => {
            parseGoalResponses(resp.data.data);
        }).catch( err => {
            console.log(err);
            toast('Unauthorized action');
        });
    }

    const parseGoalResponses = ( data: any ) => {
        let aCount = 0;
        let cCount = 0;
        data.forEach( (item: any) => {
            if ( item.is_complete ) {
                cCount++;
            } else {
                aCount++;
            }
        });
        setActiveGoals(aCount);
        setCompletedGoals(cCount);
    }

	const user = JSON.parse(localStorage.getItem('user')!);

    const remove = () => {
        deleteEntry(props.result.id).then( resp => {
            toast('Connection removed');
            props.handleReload(true);
            handleModalClose();
        }).catch( err => {
            console.log(err);
            toast('Unauthorized action');
        });
    }

    const handleModalOpen = () => {
		setShowModal(true);
	}

	const handleModalClose = () => {
		setShowModal(false);
	}

	return (

        <tr>
            <td><a href={'users/' + props.result.teacher.id}>{props.result.teacher.id}</a></td>
            <td><a href={'users/' + props.result.teacher.id}>{props.result.teacher.first_name} {props.result.teacher.last_name}</a></td>
            <td>{props.result.teacher.email}</td>
            <td>{props.result.teacher.login_count}</td>
            <td>{activeGoals}</td>
            <td>{completedGoals}</td>
            <td>{props.result.meetings.length > 0 ? props.result.meetings[0].date : '-'}</td>
            <td>
                <Button variant="danger" size="sm" onClick={handleModalOpen}>Remove</Button>
                <Modal show={showModal} onHide={handleModalClose}>
                    <Modal.Header closeButton>
                    <Modal.Title>Remove connection</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Are you sure you would like to remove your connection with this user?</p>
                        <Button variant="danger" onClick={remove}>Remove</Button>
                    </Modal.Body>
                </Modal>
            </td>
        </tr>


	)
}