import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { ReactComponent as Logo } from '../images/maximize-logo.svg';
import { logOut } from '../_services/auth';


export default function LogoutPage(props: any) {

	document.title = 'Log Out | Maximize Project';

	useEffect(() => {
		logOutUser();
	}, []);

	const logOutUser = () => {
		logOut().then(resp => {
			localStorage.removeItem('user');
			localStorage.removeItem('token');
			localStorage.removeItem('session_id');
		}).catch(err => {
			console.log(err);
		})
	}

	return (
		<div className="panel text-center">
			<Logo id="logo" />
			<h3 className="mb-4">You have been logged out.</h3>
			<Link className="btn btn-primary btn-lg w-100" to="/login">
				Log in
			</Link>
		</div>
	)
}