import React, { useState, useEffect } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Card from 'react-bootstrap/Card';
import { toast } from 'react-toastify';
import { deleteEntry } from '../../_services/coach-teacher';

export default function Coach(props: any) {

    const [showModal, setShowModal] = useState<boolean>(false);

    const remove = () => {
        deleteEntry(props.result.id).then(resp => {
            toast('Connection removed');
            props.handleReload(true);
            handleModalClose();
            window.location.reload();
        }).catch(err => {
            console.log('deleteEntry', err);
            toast('Unauthorized action');
        });
    }

    const handleModalOpen = () => {
        setShowModal(true);
    }

    const handleModalClose = () => {
        setShowModal(false);
    }

    return (
        <div className="coach-container">
            <div className="panel">
                <div className="d-flex justify-content-between align-items-center">
                    <div className="coach-info">
                        <p className="mb-0 fw-bold">{props.result.coach.first_name} {props.result.coach.last_name}</p>
                        <p className="mb-0">{props.result.coach.email}</p>
                    </div>
                    <Button variant={'danger'} size={'sm'} onClick={handleModalOpen}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
                            <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
                        </svg>
                    </Button>
                </div>
            </div>
            <Modal show={showModal} onHide={handleModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Remove connection</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you would like to remove this connection?</p>
                    <Button variant="danger" onClick={remove}>Remove</Button>
                </Modal.Body>
            </Modal>
        </div>


    )
}