import React, {useState, useEffect} from "react";
import Table from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown'
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import {Link} from "react-router-dom";
import { toast } from 'react-toastify';
import { parseISO, format } from 'date-fns';
import 'react-toastify/dist/ReactToastify.css';
import { deleteMeeting } from '../../_services/meeting';


export default function MeetingRow( props: any ) {

    const [ showModal, setShowModal ] = useState<boolean>(false);

    const remove = () => {
        deleteMeeting(props.meeting.id).then( resp => {
            toast('Meeting deleted');
            props.handleReload(true);
            handleModalClose();
        }).catch( err => {
            console.log(err);
            toast('Unauthorized action');
        });
    }

    const handleModalOpen = () => {
		setShowModal(true);
	}

	const handleModalClose = () => {
		setShowModal(false);
	}

	return (
		<tr>
            <td>{props.meeting.date}</td>
            <td>{props.meeting.topic}</td>
            <td>
                <div className="d-flex align-items-center">
                    <Button variant="secondary" size="sm" className="me-2" href={'/meeting-details?meetingId=' + props.meeting.id}>View</Button>
                    <Button variant="danger" size="sm" onClick={handleModalOpen}>Delete</Button>
                </div>
            </td>
            <Modal show={showModal} onHide={handleModalClose}>
                <Modal.Header closeButton>
                <Modal.Title>Delete meeting</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you would like to delete this meeting?</p>
                    <div className="d-flex align-items-center">
                        <Button variant="secondary" className="me-2" onClick={handleModalClose}>Cancel</Button>
                        <Button variant="danger" onClick={remove}>Delete</Button>
                    </div>
                </Modal.Body>
            </Modal>
        </tr>
	)
}