import { get, post, put, destroy } from '../xhr';

export function getGoals(request?:any) {
	let url = 'goal';
	if ( request ) {
		if ( request.is_complete ) {
			url = url + '?is_complete=true';
		}
		if ( request.is_archived ) {
			url = url + '?is_archived=true';
		}
	}
	return get(url);
}

export function getGoalsByUser(id: number) {
	let url = 'goal/' + id;
	return get(url);
}

export function createGoal( request: any ) {
	let url = 'goal';
	return post(url, request);
}

export function updateGoal( id: any, request: any ) {
	let url = 'goal/' + id;
	return put(url, request);
}

export function deleteGoal( id: any ) {
	let url = 'goal/' + id;
	return destroy(url);
}