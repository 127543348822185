import React, {useState, useEffect} from "react";
import Button from 'react-bootstrap/Button';
import Pagination from 'react-bootstrap/Pagination';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Link} from "react-router-dom";
import { toast } from 'react-toastify';
import { getUsers } from '../_services/user';
import UserResultsTable from '../components/user/user-results-table';
import CreateUser from '../components/user/create-user';
import MyConnections from '../components/my-connections';

export default function UsersPage( props: any ) {

	document.title = 'Users | Maximize Project';

	const [ isReady, setIsReady ] = useState<boolean>(false);
	const [ userResults, setUserResults ] = useState<any>();
	const [ paginationNav, setPaginationNav ] = useState<any>();
	const [ activePage, setActivePage ] = useState<number>(1);
	const [ activeFilters, setActiveFilters ] = useState<any>();
	const [ searchFilters, setSearchFilters ] = useState<any>({
		last_name: '',
		email: '',
		school: '',
		cohort: '',
		is_admin: '',
		is_coach: '',
		roles: ''
	});
	const user = JSON.parse(localStorage.getItem('user')!);

	useEffect(() => {
		if ( !isReady ) {
			loadUserList(activePage, searchFilters);
		}
	});

	useEffect(() => {
		loadUserList(1, activeFilters);
	}, [activeFilters]);

	const handleReload = () => {
		loadUserList(activePage, searchFilters);
	}

	const loadUserList = (page: number, filters: any) => {
		getUsers(page, filters).then( resp => {
			setUserResults(resp.data.data);
			setIsReady(true);
			buildPagination(resp.data.data.meta.pagination);
		}).catch( err => {
			toast('There was an error loading the user list.');
			setIsReady(true);
		})
	}

	const buildPagination = (data:any) => {
		let elements = [];
		setActivePage(data.current_page);
		for (let i=1; i<=data.total_pages; i++) {
			elements.push(<Pagination.Item key={i} active={i === data.current_page} onClick={handlePaginationClick}>{i}</Pagination.Item>);
		}
		setPaginationNav(elements);
	}

	const handlePaginationClick = (e:any) => {
		//console.log('page click', searchFilters);
		let page = parseInt(e.target.innerText);
		setActivePage(page);
		loadUserList(page, searchFilters);
	}

	const handleTextEntry = (event: any) => {
		let data = { 
			[event.target.id]: event.target.value 
		};
		setSearchFilters({...searchFilters, ...data});
	}

	const handleSelectChange = (event: any) => {
		let key = event.target.value;
		let data = {
			[key]: 1,
			roles: event.target.value
		}
		setSearchFilters({...searchFilters, ...data});
	}

	const handleReset = () => {
		//console.log('reset', searchFilters);
		setSearchFilters({
			last_name: '',
			email: '',
			school: '',
			cohort: '',
			is_admin: '',
			is_coach: '',
			roles: ''
		});
		setActiveFilters({});
	}

	const handleSubmit = (event: any) => {
		//console.log('submit', searchFilters);
		event.preventDefault();
		event.stopPropagation();
		setActivePage(1);
		setActiveFilters(searchFilters);
		//console.log('submit form');
	}


	return (
		isReady ?
		<>
			<MyConnections handleReload={handleReload} />
			<div className="panel" id="users-page">
				<div className="panel-header">
					<h1>Users</h1>
					{
						user.is_admin ?
						<CreateUser handleReload={handleReload} />
						:
						<></>
					}
					
				</div>
				<div className="search-filters mb-4">
					<Form noValidate onSubmit={handleSubmit}>
						<Row>
							<Col xs={12} sm={6} md={4}>
								<Form.Group className="mb-3">
									<Form.Label>Last Name</Form.Label>
									<Form.Control type="text" id="last_name" value={searchFilters.last_name} onChange={handleTextEntry} />
								</Form.Group>
							</Col>
							<Col xs={12} sm={6} md={4}>
								<Form.Group className="mb-3">
									<Form.Label>Email</Form.Label>
									<Form.Control type="text" id="email" value={searchFilters.email} onChange={handleTextEntry} />
								</Form.Group>
							</Col>
							{
								user.is_admin ?
								<>
									<Col xs={12} sm={6} md={4}>
										<Form.Group className="mb-3">
											<Form.Label>School</Form.Label>
											<Form.Control type="text" id="school" value={searchFilters.school} onChange={handleTextEntry} />
										</Form.Group>
									</Col>
									<Col xs={12} sm={6} md={4}>
										<Form.Group className="mb-3">
											<Form.Label>Cohort</Form.Label>
											<Form.Control type="text" id="cohort" value={searchFilters.cohort} onChange={handleTextEntry} />
										</Form.Group>
									</Col>
									<Col xs={12} sm={6} md={4}>
										<Form.Group className="mb-3">
											<Form.Label>Role</Form.Label>
											<Form.Select id="roles" value={searchFilters.roles} onChange={handleSelectChange} >
												<option>Choose...</option>
												<option value="is_admin">Administrator</option>
												<option value="is_coach">Maxmimize Team Member</option>
											</Form.Select>
										</Form.Group>
									</Col>
								</>
								:
								<></>
							}
							
							<Col xs={12} sm={6} md={4}>
								<Form.Group className="mb-4 mt-4 mt-sm-0">
									<Form.Label className="d-none d-sm-block">&nbsp;</Form.Label>
									<div className="d-flex">
									<Button variant="secondary" className="me-2" onClick={handleReset}>Reset</Button>
									<Button variant="primary" type="submit">Search</Button>
									</div>
								</Form.Group>
							</Col>
						</Row>
					</Form>
				</div>
				<UserResultsTable results={userResults} handleReload={handleReload} />
				<Pagination>{paginationNav}</Pagination>
			</div>
		</>
		:
		<div className="panel">Loading...</div>
	)
}