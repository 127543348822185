import React from "react";
import { Outlet } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import MainNav from '../main-nav';

export default function StandardFluidLayout(props: any) {
	return (
		<div className="standard-layout">
			<MainNav />
			<Container fluid className="main-container">
				<Row className="justify-content-center">
					<Col xs={12}>
						<Outlet />
					</Col>
				</Row>
			</Container>
		</div>
	)
}