import React, { useState, useEffect } from "react";
import Form from 'react-bootstrap/Form';
import Accordion from 'react-bootstrap/Accordion';
import Table from 'react-bootstrap/Table';

import 'react-toastify/dist/ReactToastify.css';
import KeyFeature from "./key-feature";
import './style.scss';


export default function StrategyInput(props: any) {

    const [isChecked, setIsChecked] = useState<boolean>(props.isChecked);
    const [ overviewElement, setOverviewElement ] = useState<any>(null);
    const [ keyFeatureElements, setKeyFeatureElements ] = useState<any>(null);

    useEffect(() => {
        buildElements();
        setIsChecked(props.isChecked);
    }, [props]);

    const buildElements = () => {
        let overviewElement;
        let keyFeatureElements:any = [];
        let rating: number = 0;
        let features = props.strategy.features;
        let recordedFeatureRatings: any;

        let recordedStrat = props.observation.strategies.find( (x:any) => { 
            return x.strategy_id === props.strategy.id  
        });

        if ( recordedStrat !== undefined ) {
            recordedFeatureRatings = recordedStrat.features;
        }

        props.strategy.features.forEach((keyFeature: any, index: number) => {
            rating = 0;
            let recordedFeatureRating: any;
            if ( recordedFeatureRatings !== undefined ) {
                recordedFeatureRating = recordedFeatureRatings.find( (x:any) => { return x.feature_id === keyFeature.id });
            }

            if (recordedFeatureRating !== undefined ) {
                rating = recordedFeatureRating.rating;
            }

            if ( keyFeature.is_overview ) {
                overviewElement = (
                    <KeyFeature key={index} feature={keyFeature} isOverview={true} onRadioChange={handleRadioChange} rating={rating} />
                );
            } else {
                keyFeatureElements.push(
                    <KeyFeature key={index} feature={keyFeature} isOverview={false} onRadioChange={handleRadioChange} rating={rating} />
                );
            }
        });
        
        setOverviewElement(overviewElement);
        setKeyFeatureElements(keyFeatureElements);
        
    }

    const handleRadioChange = (event: any) => {
        props.onRadioChange(event, props.strategy.id);
    }

    const handleCheck = (event: any) => {
        
    }

    const handleAccordionClick = (event: any) => {
        setIsChecked(!isChecked);
        props.onCheckChange( props.strategy.id, !isChecked );
    }

    return (
        <Accordion.Item eventKey={props.index.toString()} className="strategy-input">
            <Accordion.Header onClick={handleAccordionClick}>
                <Form.Check>
                    <Form.Check.Input type="checkbox" name={props.strategy.id} value={props.strategy.id} onChange={handleCheck} checked={isChecked} />
                </Form.Check>
                <span className="strategy-name">{props.strategy.name}</span>
            </Accordion.Header>
            <Accordion.Body>
                <Table className="observation-form-table">
                    <thead>
                        <tr>
                            <th>Global Rating</th>
                            <th>Area for Growth</th>
                            <th>Good Enough</th>
                            <th>Strength</th>
                        </tr>
                    </thead>
                    <tbody>
                        {overviewElement}
                    </tbody>
                    <thead>
                        <tr>
                            <th>Key Features</th>
                            <th></th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {keyFeatureElements}
                    </tbody>
                </Table>
            </Accordion.Body>
        </Accordion.Item>
    )
}









