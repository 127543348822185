import { get, post, put, destroy } from '../xhr';


export function getMyMeetings() {
	let url = 'meeting';
	return get(url);
}

export function getMeetingById(id: number) {
	let url = 'meeting/' + id;
	return get(url);
}

export function getMeetingsByTeacher(id: number) {
	let url = 'meeting/teacher/' + id;
	return get(url);
}

export function createMeeting( request: any ) {
	let url = 'meeting';
	return post(url, request);
}

export function updateMeeting( id: any, request: any ) {
	let url = 'meeting/' + id;
	return put(url, request);
}

export function deleteMeeting( id: any ) {
	let url = 'meeting/' + id;
	return destroy(url);
}

export function createMeetingReview( request: any, type: string ) {
	let url = '';
	if ( type === 'coach' ) {
		url = 'meeting-review-coach';
	} else {
		url = 'meeting-review-teacher';
	}
	return post(url, request);
}

export function updateMeetingReview( id: number, request: any, type: string ) {
	let url = '';
	if ( type === 'coach' ) {
		url = 'meeting-review-coach';
	} else {
		url = 'meeting-review-teacher';
	}
	url = url + '/' + id;
	return put(url, request);
}