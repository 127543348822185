import React, {useState} from "react";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import {Link} from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import { requestPasswordReset } from '../../_services/auth';

const RequestPasswordResetForm = () => {

  const [ isProcessing, setIsProcessing ] = useState<boolean>(false);
  const [ email, setEmail ] = useState<string>('');
  const [validated, setValidated] = useState<boolean>(false);

  let navigate = useNavigate();

  const handleSubmit = (event:any) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    setValidated(true);
    if (form.checkValidity() === true) {
      sendRequest();
    }
  }

  const sendRequest = () => {
    setIsProcessing(true);
    let request = {
      email: email
    }
    requestPasswordReset(request).then( (resp:any) => {
      toast('A password reset link sent has been sent to your email.');
      navigate("../login", { replace: true });
      setIsProcessing(false);
    }).catch( (err:any) => {
      toast('Password reset request failed');
      console.log(err);
      setIsProcessing(false);
    });
  }

  const handleTextEntry = (event: any) => {
    let value = event.target.value;
    setEmail(value);
  }

  return (
    <div className="login-form">
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <p className="mb-4">Please enter your account email address to request a password reset.</p>
        <Form.Group className="mb-4" controlId="email">
          <Form.Control type="email" placeholder="Enter email" onChange={handleTextEntry} required />
        </Form.Group>
        <Button variant="primary" size="lg" type="submit">
          { isProcessing ? 'Processing...' : 'Continue' }
        </Button>
        <Link to="/login" className="text-center mt-4">Don't need this? Sign in</Link>
      </Form>
    </div>
  );
};

export default RequestPasswordResetForm;