import { get, post, put, destroy } from '../xhr';

export function getAllReflections() {
	let url = 'reflection';
	return get(url);
}

export function createReflection(request:any) {
	let url = 'reflection';
	return post(url, request);
}

export function updateReflection(id:number, request:any) {
	let url = 'reflection/' + id;
	return put(url, request);
}

export function deleteReflection(id:number) {
	let url = 'reflection/' + id;
	return destroy(url);
}