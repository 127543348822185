import React, { useState, useEffect } from "react";
import Form from 'react-bootstrap/Form';
import 'react-toastify/dist/ReactToastify.css';


export default function KeyFeature(props: any) {

    useEffect(() => {
        //console.log('KeyFeature', props);
    }, []);

    const handleRadioChange = (event: any) => {
        props.onRadioChange(event);
    }

    return (
        <tr>
            <td>
                {props.feature.description}
            </td>
            <td>
                <Form.Check type="radio" name={props.feature.id} value="1" onChange={handleRadioChange} checked={props.rating === 1} />
            </td>
            <td>
                <Form.Check type="radio" name={props.feature.id} value="2" onChange={handleRadioChange} checked={props.rating === 2} />
            </td>
            <td>
                <Form.Check type="radio" name={props.feature.id} value="3" onChange={handleRadioChange} checked={props.rating === 3} />
            </td>
        </tr>
    )
}









