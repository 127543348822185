import React, {useState, useEffect} from "react";
import Button from 'react-bootstrap/Button';
import {Link} from "react-router-dom";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { toast } from 'react-toastify';
import {ReactComponent as Logo} from '../images/maximize-logo.svg';
import LoginForm from "../components/auth/login-form";

export default function LoginPage( props: any ) {

	document.title = 'Log In | Maximize Project';

	useEffect(() => {
	    if ( localStorage.getItem('user') !== null ) {
	    	localStorage.removeItem('user');
		}
		if ( localStorage.getItem('token') !== null ) {
	    	localStorage.removeItem('token');
	    	toast('Account session has expired. Please login again.');
		}
	});

	return (
		<div className="card panel">
			<Logo id="logo" />
			<LoginForm />
		</div>
	)
}