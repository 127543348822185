import React, {useState, useEffect} from "react";
import { useParams } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Link} from "react-router-dom";
import { parseISO, format, differenceInDays } from 'date-fns';
import UserGoals from '../components/user-goals';
import Meetings from '../components/meetings';
import Observations from '../components/observations';
import Profile from '../components/profile';
import Loader from '../components/loader';



export default function UserProfilePage( props: any ) {

	let { id } = useParams();

	useEffect(() => {
	}, []);
	
	document.title = 'User Profile | Maximize Project';

	return (
		<div className="user-details-page">
			<Row>
				<Col xs={12}>
				{ id && <Profile userId={id} />}
				</Col>
			</Row>
		</div>
	)
}

