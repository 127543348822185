import React, { useState, useEffect } from "react";
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Loader from '../loader';
import MeetingRow from './meeting-row';
import { getMeetingsByTeacher } from '../../_services/meeting';
import { ElementFlags } from "typescript";

export default function Meetings(props: any) {

    const [meetings, setMeetings] = useState<any>();
    const [isReady, setIsReady] = useState<boolean>(false);

    useEffect(() => {
        getMeetings();
    }, []);

    useEffect(() => {
        if (meetings) {
            console.log(meetings);
            setIsReady(true);
        }
    }, [meetings]);

    const getMeetings = () => {
        getMeetingsByTeacher(props.userId).then(resp => {
            buildElements(resp.data.data);
        }).catch(err => {
            console.log(err);
        });
    }

    const buildElements = (data: any) => {
        let elements: any = [];

        data.forEach((item: any, index: number) => {
            elements.push(<MeetingRow key={index} meeting={item} handleReload={getMeetings} />);
        });

        setMeetings(elements);
        setIsReady(true);
    }

    return (
        isReady ?
            <div className="meetings-container">
                {
                    meetings.length > 0 ?
                        <Table>
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Topics</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {meetings}
                            </tbody>
                        </Table>
                        :
                        <p className="m-0">You have no meetings with this user.</p>
                }
            </div>
            :
            <p className="m-0">You have no meetings with this user.</p>
    )
}

