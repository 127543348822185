import { get, post, destroy } from '../xhr';

export function getReport() {
	let url = 'download_data_export';
	return get(url);
}

export function indexReports() {
	let url = 'data-export';
	return get(url);
}

export function generateReport(request: any) {
	return post( 'data-export/generate', request );
}

export function deleteReport(request: any) {
	return destroy( 'data-export/' + request.file );
}

export function generateCertificate() {
	let url = 'certificate_of_achievement';
	return get(url);
}