import React, {useState, useEffect} from "react";
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Dropdown from 'react-bootstrap/Dropdown'
import {Link} from "react-router-dom";
import './style.scss';
import {saveMyConsent} from '../../_services/user-consent';
import { toast } from 'react-toastify';


export default function TermsSection( props: any ) {

	useEffect(() => {
	});

    const toggleCheck = (event: any) => {
        saveMyConsent({
            terms_id: props.item.id,
            status: parseInt(event.target.value)
        }).then( resp => {
            toast('Input saved successfully');
            props.reload(true);
        });
    }

	return (
		<div className="terms-section mb-4">
			<h6 className="fw-bold">Choose one to continue:</h6>
			<div>
				<Form.Check 
		            type="radio"
		            id={props.item.id + '-yes'}
					name="terms"
		            label="I authorize use of my de-identified data by the Ohio University Research Team."
                    onChange={toggleCheck}
                    checked={props.status == 1 ? true : false}
					value={1}
		        />
				<Form.Check 
		            type="radio"
		            id={props.item.id + '-no'}
					name="terms"
		            label="I do not authorize use of my de-identified data by the Ohio University Research Team."
                    onChange={toggleCheck}
                    checked={props.status == 0 ? true : false}
					value={0}
		        />
			</div>
		</div>
	)
}

