import React, { useState, useEffect } from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Placeholder from 'react-bootstrap/Placeholder';

import { ReactComponent as DiamondIcon } from '../../images/icons/diamond.svg';
import { ReactComponent as StarIcon } from '../../images/icons/star.svg';
import { ReactComponent as LightbulbIcon } from '../../images/icons/lightbulb.svg';
import { ReactComponent as SunIcon } from '../../images/icons/sun.svg';
import { ReactComponent as WaterIcon } from '../../images/icons/water.svg';
import { ReactComponent as CheckIcon } from '../../images/icons/check.svg';

export default function Stats(props: any) {

    const [goalCount, setGoalCount] = useState<any>(null);
    const [reflectionCount, setReflectionCount] = useState<any>(null);
    const [assessmentCount, setAssessmentCount] = useState<any>(null);
    const [gemCount, setGemCount] = useState<any>(null);

    useEffect(() => {
        if (props.user !== null) {
            countReflections();
            countCompletedGoals();
            countSelfAssessments();
        }
    }, [props.user]);

    useEffect(() => {
        if (reflectionCount !== null && goalCount !== null && assessmentCount !== null) {
            countGems();
        }
    }, [reflectionCount, goalCount, assessmentCount]);

    const countReflections = () => {
        const cutoffDate = getCutoffStartDate();
        let count = 0;
        props.user.reflections.forEach((reflection: any) => {
            const reflectionDate = new Date(reflection.created_at);
            if (reflectionDate > cutoffDate) {
                count++;
            }
        });
        setReflectionCount(count);
    }

    const countCompletedGoals = () => {
        const cutoffDate = getCutoffStartDate();
        let count = 0;
        props.user.goals.forEach((goal: any) => {
            const goalDate = new Date(goal.created_at);
            if (goal.is_complete && goalDate > cutoffDate) {
                count++;
            }
        });
        setGoalCount(count);
    }

    const countSelfAssessments = () => {
        let count = 0;
        const cutoffDate = getCutoffStartDate();
        props.user.self_assessments.forEach((item: any) => {
            const date = new Date(item.created_at);
            if (date > cutoffDate) {
                count++;
            }
        });
        setAssessmentCount(count);
    }

    const countGems = () => {
        let n = (reflectionCount + assessmentCount) * .25;
        n += goalCount;
        if (n > 10) {
            n = 10;
        }
        setGemCount(n);
    }

    const getCutoffStartDate = () => {
        const currentYear = new Date().getFullYear();
        const cutOffDate = new Date(`${currentYear}-${process.env.REACT_APP_CUT_OFF_DATE}`);
        const today = new Date();
        let date;
        if (today < cutOffDate) {
            date = new Date(`${currentYear - 1}-${process.env.REACT_APP_CUT_OFF_DATE}`);
        } else {
            date = cutOffDate;
        }
        return date;
    };

    return (

        <Row className="mb-5">
            <Col xs={12}>
                <h2>Achievements (2024-25)</h2>
            </Col>
            <Col xs={12} md={6} xl={3} className="mb-4 mb-xl-0">
                <div className="panel d-flex align-items-center">
                    <div className="icon">
                        <SunIcon />
                    </div>
                    {
                        gemCount !== null ?
                            <div>
                                <h3>{gemCount} <span className="text-sm d-inline">/10</span></h3>
                                <span>Contact Hours</span>
                            </div>
                            :
                            <Placeholder as="div" className="stat-placeholder" size="lg" />
                    }

                </div>
            </Col>
            <Col xs={12} md={6} xl={3} className="mb-4 mb-xl-0">
                <div className="panel d-flex align-items-center">
                    <div className="icon">
                        <CheckIcon style={{'width': '32px'}} />
                    </div>
                    {goalCount !== null ?
                        <div>
                            <h3>{goalCount}</h3>
                            <span>Goals Completed</span>
                        </div>
                        :
                        <Placeholder as="div" className="stat-placeholder" size="lg" />
                    }

                </div>
            </Col>
            <Col xs={12} md={6} xl={3} className="mb-4 mb-xl-0">
                <div className="panel d-flex align-items-center">
                    <div className="icon">
                        <LightbulbIcon />
                    </div>
                    {
                        reflectionCount !== null ?
                            <div>
                                <h3>{reflectionCount}</h3>
                                <span>Reflections</span>
                            </div>
                            :
                            <Placeholder as="div" className="stat-placeholder" size="lg" />
                    }
                </div>
            </Col>
            <Col xs={12} md={6} xl={3} className="mb-4 mb-xl-0">
                <div className="panel d-flex align-items-center">
                    <div className="icon">
                        <StarIcon />
                    </div>
                    {assessmentCount !== null ?
                        <div>
                            <h3>{assessmentCount}</h3>
                            <span>Self-assessments</span>
                        </div>
                        :
                        <Placeholder as="div" className="stat-placeholder" size="lg" />
                    }

                </div>
            </Col>
        </Row>
    );
}