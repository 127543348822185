import React, { useState, useEffect } from "react";
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';

import { createEntry, deleteEntry } from '../../_services/coach-teacher';
import { ReactComponent as CheckIcon } from '../../images/icons/check.svg';
import { ReactComponent as HourglassIcon } from '../../images/icons/hourglass.svg';

export default function InviteUser(props: any) {

    const [state, setState] = useState<string>('neutral');
    const [entry, setEntry] = useState<any>();
    const [showModal, setShowModal] = useState<boolean>(false);

    useEffect(() => {
        updateButtonState();
    }, [props]);

    const updateButtonState = () => {
        let string = 'neutral';
        let currentUserId = JSON.parse(localStorage.getItem('user')!).id;
        props.user.coach_teacher.forEach((item: any) => {
            if (item.coach_id === currentUserId) {
                if (item.is_approved) {
                    string = 'approved';
                } else {
                    string = 'pending';
                }
                setEntry(item);
            }
        });
        setState(string);
    }

    const inviteUser = () => {
        let request = {
            teacher_id: props.user.id
        }
        createEntry(request).then(resp => {
            toast('Invitation sent');
            props.handleReload(true);
        }).catch(err => {
            console.log(err);
            toast('Unauthorized action');
        });
    }

    const remove = () => {
        deleteEntry(entry.id).then(resp => {
            toast('Connection removed');
            props.handleReload(true);
            handleModalClose();
        }).catch(err => {
            toast('Unauthorized action');
        });
    }

    const handleModalOpen = () => {
        setShowModal(true);
    }

    const handleModalClose = () => {
        setShowModal(false);
    }

    return (
        <>
            {
                state === 'neutral' ?
                    <Button variant="secondary" size="sm" onClick={inviteUser}>Invite</Button>
                    :
                    <></>
            }
            {
                state === 'approved' ?
                    <div className="d-flex justify-content-between align-items-center">
                        <CheckIcon className="me-2" />
                        <Button variant="danger" size="sm" onClick={handleModalOpen}>Remove</Button>
                    </div>
                    :
                    <></>
            }
            {
                state === 'pending' ?
                    <div className="d-flex justify-content-between align-items-center">
                        <HourglassIcon className="me-2" />
                        <Button variant="danger" size="sm" onClick={handleModalOpen}>Remove</Button>
                    </div>
                    :
                    <></>
            }
            <Modal show={showModal} onHide={handleModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Remove connection</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you would like to remove your connection with this user?</p>
                    <Button variant="danger" onClick={remove}>Remove</Button>
                </Modal.Body>
            </Modal>
        </>

    )
}