import React, {useState, useEffect} from "react";
import { useNavigate } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { parseISO, format } from 'date-fns';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createMeeting, updateMeeting, getMeetingById, createMeetingReview, updateMeetingReview } from '../../_services/meeting';
import { getUser } from '../../_services/user';
import './style.scss';

export default function MeetingForm( props: any ) {

    const navigate = useNavigate();
    
    const [ topicElements, setTopicElements ] = useState<any>({});
    const [ tierElements, setTierElements ] = useState<any>({});
    const [ isReady, setIsReady ] = useState<any>(false);
    const [ teacher, setTeacher ] = useState<any>();
    const [ topicError, setTopicError ] = useState<boolean>(false);
    const [ tierError, setTierError ] = useState<boolean>(false);

    const topic = [
        'Personalized Greetings',
        'Student Check-Ins',
        'Community Circles',
        'Establish Classroom Expectations',
        'Acknowledge Positive Behavior',
        'Corrective Feedback',
        'Teach Prosocial Skills',
        'Classroom Routines',
        'Effective Questioning',
        'Student Choice',
        'Academic Supports',
        'Check-In Intervention',
        'DRC Intervention',
        'Other'
    ];

    const tier = [
        '1',
        '1.5',
        '2'
    ];

    const [ meeting, setMeeting ] = useState<any>(() => {
        let obj = {
            date: '',
            duration: ''
        };
        topic.forEach( (item: any, index: number) => {
            Object.assign(obj, {['topic-'+index]: false})
        });
        tier.forEach( (item: any, index: number) => {
            Object.assign(obj, {['tier-'+index]: false})
        });
        return obj;
    });

    const [ meetingReview, setMeetingReview ] = useState<any>({
        rating: '',
        successess: null,
        challenges: null,
        equity_topics: null,
        next_steps: null
    });
    const [ meetingReviewId, setMeetingReviewId ] = useState<any>();

    useEffect(() => {
        buildElements();
        if ( props.meetingId !== null ) {
            getMeetingDetails();
        }
        if ( props.teacherId !== null && props.meetingId === null ) {
            getTeacherDetails();
        }
	}, []);

    useEffect(() => {
        buildElements();
	}, [meeting]);

    const buildElements = () => {
        let topicEl = topic.map(function ( item: any, index: number ) {
            return (
                <Form.Check key={'topic-'+index} inline type="checkbox" id={'topic-'+index} label={item} onChange={handleMeetingCheckbox} checked={meeting['topic-'+index] || ''} />
            )
        });
        let tierEl = tier.map(function ( item: any, index: number ) {
            return (
                <Form.Check key={'tier-'+index} inline type="checkbox" id={'tier-'+index} label={item} onChange={handleMeetingCheckbox} checked={meeting['tier-'+index] || ''}/>
            )
        });
        setTopicElements(topicEl);
        setTierElements(tierEl);
        setIsReady(true);
    }

    const getMeetingDetails = () => {
        getMeetingById(props.meetingId).then(resp => {
            populateForm(resp.data.data);
        }).catch( err => {
            //navigate('/users');
            console.log(err);
        });
    }

    const getTeacherDetails = () => {
        getUser(props.teacherId).then( resp => {
            setTeacher(resp.data.data);
        }).catch( err => {
            navigate('/users');
            console.log(err);
        });
    }

    const populateForm = (data: any) => {
        let topicArr = data.topic.split(', ');
        let tierArr = data.tier.split(', ');

        let meetingData = {
            date: data.date,
            duration: data.duration
        }
        
        topicArr.forEach( (item: any) => {
            let index = topic.indexOf(item);
            (meetingData as any)['topic-'+index] = true;
        });

        tierArr.forEach( (item: any) => {
            let index = tier.indexOf(item);
            (meetingData as any)['tier-'+index] = true;
        });

        let reviewData = {
            rating: data.meeting_review_coach.rating,
            successes: data.meeting_review_coach.successes,
            challenges: data.meeting_review_coach.challenges,
            equity_topics: data.meeting_review_coach.equity_topics,
            next_steps: data.meeting_review_coach.next_steps,
        }

        setTeacher(data.teacher);
        setMeeting(meetingData);
        setMeetingReview(reviewData);
        setMeetingReviewId(data.meeting_review_coach.id);
    }


    // ======================================
    // event handlers
    // ======================================

    const handleMeetingInput = (event: any) => {
        let data = {
            [event.target.id]: event.target.value
        }
        setMeeting({...meeting, ...data});
    }

    const handleMeetingCheckbox = (event: any) => {
        let key = event.target.id;
		let data = { 
			[key]: !meeting[key]
		};
		setMeeting({...meeting, ...data});
	}

    const handleReviewCheckbox = (event: any) => {
		let data = { 
			rating: event.target.value
		};
		setMeetingReview({...meetingReview, ...data});
	}

    const handleReviewTextInput = (event: any) => {
        let data = {
            [event.target.id]: event.target.value
        }
        setMeetingReview({...meetingReview, ...data});
    }

    const handleSubmit = (event: any) => {
        event.preventDefault();
        console.log('handleSubmit');
        if ( getStringFromObject(topic, 'topic') === '' ) {
            setTopicError(true);
            window.scrollTo(0, 0);
            return false;
        } else {
            setTopicError(false);
        }
        if ( getStringFromObject(tier, 'tier') === '' ) {
            setTierError(true);
            window.scrollTo(0, 0);
            return false;
        } else {
            setTierError(false);
        }
        let request = {
            teacher_id: teacher.id,
            date: meeting.date,
            duration: meeting.duration,
            topic: getStringFromObject(topic, 'topic'),
            tier: getStringFromObject(tier, 'tier')
        }

        if ( props.meetingId === null ) {
            createMeeting(request).then( resp => {
                handleCreateMeetingReview(resp.data.data.id);
            }).catch( err => {
                toast('Failed to save meeting data');
                console.log(err);
            });
        } else {
            updateMeeting(props.meetingId, request).then( resp => {
                handleUpdateMeetingReview();
            }).catch( err => {
                navigate('/users/' + teacher.id);
                toast('Failed to save meeting data');
                console.log(err);
            });
        }
        
    }

    const handleCreateMeetingReview = (meetingId: number) => {

        let request: any = {
            meeting_id: meetingId,
            rating: meetingReview.rating,
            next_steps: meetingReview.next_steps
        };
        if ( meetingReview.successes ) {
            request.successes = meetingReview.successes;
        }
        if ( meetingReview.challenges ) {
            request.challenges = meetingReview.challenges;
        }
        if ( meetingReview.equity_topics ) {
            request.equity_topics = meetingReview.equity_topics;
        }

        createMeetingReview(request, 'coach').then( resp => {
            navigate('/users/' + teacher.id);
            toast('Meeting saved');
        }).catch( err => {
            console.log(err);
            toast('Failed to save meeting data');
        });
    }

    const handleUpdateMeetingReview = () => {
        let request = {
            rating: meetingReview.rating,
            successes: meetingReview.successes,
            challenges: meetingReview.challenges,
            equity_topics: meetingReview.equity_topics,
            next_steps: meetingReview.next_steps,
        }
        updateMeetingReview(meetingReviewId, request, 'coach').then( resp => {
            navigate('/users/' + teacher.id);
            toast('Meeting saved');
        }).catch( err => {
            console.log(err);
            toast('Failed to save meeting data');
        });
    }

    // ======================================
    // utils
    // ======================================

    const getStringFromObject = (obj: any, type: string) => {
        let arr = [];
        for (const [key, value] of Object.entries(meeting)) {
            if ( key.includes(type) && value === true ) {
                let index = parseInt(key.replace(type + '-', ''));
                arr.push(obj[index]);
            }
        }
        return arr.join(', ');
    }

	return (
        isReady ?
            <Form className="meeting-form" id="meeting-form" onSubmit={handleSubmit}>
                <Row>
                    <Col>
                        <p className="fw-bold"><span className="required">Fields marked with</span> are required.</p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group className="mb-4">
                            <Form.Label className="fw-bold">Teacher</Form.Label>
                            <Form.Control type="text" value={teacher?.first_name + ' ' + teacher?.last_name} disabled />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mb-4">
                            <Form.Label className="fw-bold required">Date</Form.Label>
                            <Form.Control id="date" type="date" name="date" onChange={handleMeetingInput} value={meeting.date} required></Form.Control>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mb-4">
                            <Form.Label className="fw-bold required">Duration in minutes</Form.Label>
                            <Form.Control id="duration" type="number" name="number" onChange={handleMeetingInput} value={meeting.duration} min="0" max="1000" required></Form.Control>
                        </Form.Group>
                    </Col>
                    
                </Row>
                <Form.Group className="mb-4">
                    <Form.Label className="fw-bold required">Topics</Form.Label>
                    { topicError ? <Form.Text className="error">This field is required.</Form.Text> : null }
                    <div className="checkbox-group">
                        {topicElements}       
                    </div>          
                      
                </Form.Group>
                <Form.Group className="mb-4">
                    <Form.Label className="fw-bold required">Tier of support</Form.Label>
                    { tierError ? <Form.Text className="error">This field is required.</Form.Text> : null }
                    {tierElements}             
                </Form.Group>

                <Form.Group className="mb-4">
					<Form.Label className="fw-bold required">The things we discussed will help the teacher accomplish their goal.</Form.Label>
					<Form.Check inline type="radio" name="rating" label="Strongly disagree" value="0" onChange={handleReviewCheckbox} checked={parseInt(meetingReview.rating) === 0} required/>
					<Form.Check inline type="radio" name="rating" label="Disagree" value="1" onChange={handleReviewCheckbox} checked={parseInt(meetingReview.rating) === 1} required/>
					<Form.Check inline type="radio" name="rating" label="Neutral" value="2" onChange={handleReviewCheckbox} checked={parseInt(meetingReview.rating) === 2} required/>
					<Form.Check inline type="radio" name="rating" label="Agree" value="3" onChange={handleReviewCheckbox} checked={parseInt(meetingReview.rating) === 3} required/>
					<Form.Check inline type="radio" name="rating" label="Strongly agree" value="4" onChange={handleReviewCheckbox} checked={parseInt(meetingReview.rating) === 4} required/>
				</Form.Group>
				<Form.Group className="mb-4">
					<Form.Label className="fw-bold mb-0">Successes</Form.Label>
					<p>Describe successes related to the student, the teacher's implementation, and/or collaboration during the meeting.</p>
					<Form.Control as="textarea" rows={3} id="successes" onChange={handleReviewTextInput} value={meetingReview.successes} />
				</Form.Group>
				<Form.Group className="mb-4">
					<Form.Label className="fw-bold mb-0">Challenges</Form.Label>
					<p>Describe challenges related to the student, the teacher's implementation, and/or collaboration during the meeting.</p>
					<Form.Control as="textarea" rows={3} id="challenges" onChange={handleReviewTextInput} value={meetingReview.challenges} />
				</Form.Group>
				<Form.Group className="mb-4">
					<Form.Label className="fw-bold mb-0">Equity Topics</Form.Label>
					<p>Describe the equity-focused features and/or topics discussed during the meeting.</p>
					<Form.Control as="textarea" rows={3} id="equity_topics" onChange={handleReviewTextInput} value={meetingReview.equity_topics} />
				</Form.Group>
				<Form.Group className="mb-4">
					<Form.Label className="fw-bold mb-0 required">Next Steps</Form.Label>
					<p>Describe who will do what and when in the next two weeks to help the teacher meet their goal.</p>
					<Form.Control as="textarea" rows={3} id="next_steps" onChange={handleReviewTextInput} value={meetingReview.next_steps} required/>
				</Form.Group>
                <div className="d-flex justify-content-end">
                    <Button variant="primary" type="submit">Save Meeting</Button>
                </div>
            </Form>
        :
            <></>
	)

}