import React, {useState, useEffect} from "react";
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Link} from "react-router-dom";

import StrategyList from '../components/strategy-list/';

export default function StrategiesPage( props: any ) {

	document.title = 'Strategies | Maximize Project';

	return (
		<div>
			<Row>
				<Col xs={12}>
					<StrategyList />
				</Col>
			</Row>
		</div>
	)
}