import React, { useState, useEffect } from "react";
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { Link, useNavigate } from "react-router-dom";

import { getStrategyScores } from '../../_services/strategy-score';
import { handleStepCompletion } from '../../components/user-workflow/user-workflow-controller';
import { createUserWorkflow } from "../../_services/user-workflow";



export default function ChooseStrategyPage(props: any) {

    document.title = 'Choose a Strategy | Maximize Project';

    const navigate = useNavigate();

    const [scores, setScores] = useState<any>();
    const [areaForGrowth, setAreaForGrowth] = useState<any>();
    const [developing, setDeveloping] = useState<any>();
    const [skilled, setSkilled] = useState<any>();
    const [accomplished, setAccomplished] = useState<any>();
    const [selectedStrategy, setSelectedStrategy] = useState<any>();

    useEffect(() => {
        getScores();
    }, []);

    useEffect(() => {
        if (scores && scores.length > 0) {
            buildForm();
        }
    }, [scores]);


    const buildForm = () => {

        let g = scores[0].scores.filter((score: any) => {
            if (score.score === 0) {
                return true;
            }
            return false;
        }).map((score: any, index: number) => {
            return (
                <Form.Check
                    type="radio"
                    id={score.strategy.id}
                    name='strategy'
                    label={score.strategy.name}
                    key={index}
                    onChange={handleChange}
                />
            )
        });
        setAreaForGrowth(g);

        let d = scores[0].scores.filter((score: any) => {
            if (score.score === 1) {
                return true;
            }
            return false;
        }).map((score: any, index: number) => {
            return (
                <Form.Check
                    type="radio"
                    id={score.strategy.id}
                    name='strategy'
                    label={score.strategy.name}
                    key={index}
                    onChange={handleChange}
                />
            )
        });
        setDeveloping(d);

        let s = scores[0].scores.filter((score: any) => {
            if (score.score === 2) {
                return true;
            }
            return false;
        }).map((score: any, index: number) => {
            return (
                <Form.Check
                    type="radio"
                    id={score.strategy.id}
                    name='strategy'
                    label={score.strategy.name}
                    key={index}
                    onChange={handleChange}
                />
            )
        });
        setSkilled(s);

        let a = scores[0].scores.filter((score: any) => {
            if (score.score === 3) {
                return true;
            }
            return false;
        }).map((score: any, index: number) => {
            return (
                <Form.Check
                    type="radio"
                    id={score.strategy.id}
                    name='strategy'
                    label={score.strategy.name}
                    key={index}
                    onChange={handleChange}
                />
            )
        });
        console.log(a);
        setAccomplished(a);
    }

    const getScores = () => {
        getStrategyScores().then(resp => {
            setScores(resp.data.data);
            console.log(resp.data.data);
        }).catch(err => {
            console.log(err);
        });
    }

    const handleChange = (e: any) => {
        setSelectedStrategy(e.target.id);
    }

    // onsubmit, set main workflow to complete, start strategy workflow with selected strategy
    const handleSubmit = (e: any) => {
        e.preventDefault();
        startNewWorkflow();
    }

    const startNewWorkflow = () => {
        let request = {
            workflow_id: 3,
            current_step: 1,
            current_step_status: 'untouched',
            is_active: true,
            is_complete: false,
            strategy_id: selectedStrategy
        };

        createUserWorkflow(request).then((resp: any) => {
            //window.location.href = '/workflow-routing';
            let stepCompletionReq = {
                workflowName: 'Main',
                stepNumber: 2,
                strategyId: selectedStrategy
            }
            handleStepCompletion(stepCompletionReq);
        }).catch((err: any) => {
            console.log(err);
        });
    }

    return (
        <div className="onboarding-page mb-5">
            <div className="onboarding-reflection mb-5">
                <Row className="justify-content-center">
                    <Col xs={12} lg={8}>
                        <h1>Choose a strategy</h1>
                        <p className="mb-5">Below you will see your profile of strengths and areas for growth. We encourage you to choose a strategy from the "Area for Growth" or "Developing" sections.</p>
                    </Col>
                    <Col xs={12} md={8} xl={6}>
                        <Form onSubmit={handleSubmit}>

                            {
                                (scores) &&
                                <Row>
                                    <Col xs={12}>
                                        <div className="strategy-category">
                                            <h3>Area for Growth</h3>
                                            {areaForGrowth && areaForGrowth.length > 0 ? areaForGrowth : <span>No strategies in this category</span>}
                                        </div>
                                    </Col>

                                    <Col xs={12}>
                                        <div className="strategy-category">
                                            <h3>Developing</h3>
                                            {developing && developing.length > 0 ? developing : <span>No strategies in this category</span>}
                                        </div>
                                    </Col>

                                    <Col xs={12}>
                                        <div className="strategy-category">
                                            <h3>Skilled</h3>
                                            {skilled && skilled.length > 0 ? skilled : <span>No strategies in this category</span>}
                                        </div>
                                    </Col>


                                    <Col xs={12}>
                                        <div className="strategy-category">
                                            <h3>Accomplished</h3>
                                            {accomplished && accomplished.length > 0 ? accomplished : <span>No strategies in this category</span>}
                                        </div>
                                    </Col>
                                </Row>

                            }
                        </Form>
                    </Col>
                </Row>
            </div>
            <div className="action-button-container">
                <Container>
                    <Row className="justify-content-center">
                        <Col xs={12} xl={9}>
                            <div className="d-flex justify-content-between">
                                <Button variant="secondary" onClick={() => navigate('/my-profile')}>Back</Button>
                                <div className="d-flex align-items-center">
                                    <Button className="btn btn-primary" onClick={handleSubmit} disabled={!selectedStrategy}>Continue</Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}
