import React, { useState, useEffect } from "react";

import Placeholder from 'react-bootstrap/Placeholder';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { useNavigate } from "react-router-dom";
import { getMyUserWorkflows } from "../_services/user-workflow";

import { ReactComponent as Logo } from '../images/maximize-logo.svg';

export default function WorkflowRoutingPage(props: any) {

    document.title = 'Routing... | Maximize Project';

    const navigate = useNavigate();

    const [currentWorkflow, setCurrentWorkflow] = useState<any>();

    useEffect(() => {
        getMyUserWorkflows().then((resp: any) => {
            let workflows = resp.data.data;
            let activeWorkflowExists = false;
            workflows.forEach((item: any) => {
                if (!item.is_complete && item.is_active) {
                    setCurrentWorkflow(item);
                    activeWorkflowExists = true;
                }
            });
            if (!activeWorkflowExists) {
                navigate('../strategies', { replace: true });
            }
        }).catch((err: any) => {
            console.log(err);
        });
    }, []);

    useEffect(() => {
        if (currentWorkflow) {
            console.log('currentWorkflow', currentWorkflow);
            handleWorkflowRouting();
        }
    }, [currentWorkflow]);



    const handleWorkflowRouting = () => {
        switch (currentWorkflow.workflow.name) {
            case 'Onboarding':
                handleOnboarding();
                break;
            case 'Main':
                handleMainWorkflow();
                break;
            case 'Strategy':
                handleStrategyWorkflow();
                break;
            default:
                break;
        }
    }

    const handleOnboarding = () => {
        let currentStepOrder = currentWorkflow.current_step;
        let stepStatus = currentWorkflow.current_step_status;

        let currentStep = currentWorkflow.workflow.steps.find((step: any) => {
            return step.order === currentStepOrder;
        });

        switch (currentStep.order) {
            case 1:
                navigate('../onboarding-welcome', { replace: true });
                break;
            case 2:
                navigate('../onboarding-reflection/' + process.env.REACT_APP_ONBOARDING_REFLECTION_1_SURVEY_ID, { replace: true });
                break;
        }

    }

    const handleMainWorkflow = () => {
        let currentStepOrder = currentWorkflow.current_step;
        let stepStatus = currentWorkflow.current_step_status;

        let currentStep = currentWorkflow.workflow.steps.find((step: any) => {
            return step.order === currentStepOrder;
        });

        switch (currentStep.order) {
            case 1:
                navigate('../self-assessment', { replace: true });
                break;
            case 2:
                navigate('../strategies', { replace: true });
                break;
        }
    }

    const handleStrategyWorkflow = () => {
        if (currentWorkflow.strategy) {
            navigate('../strategy-workflow/' + currentWorkflow.strategy.id, { replace: true });
        } else {
            navigate('../strategies', { replace: true });
        }
    }


    return (
        <div className="loader-container">
            <div className="loader"></div>
        </div>
    )
}