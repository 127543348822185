import React, {useState, useEffect} from "react";
import { toast } from 'react-toastify';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import {Link} from "react-router-dom";
import PasswordStrengthBar from 'react-password-strength-bar';
import { createUser, updateUser } from '../../_services/user';

export default function UserForm( props: any ) {
	const [ user, setUser ] = useState<any>({});
	const [ isReady, setIsReady ] = useState<boolean>(false);
	const [ isProcessing, setIsProcessing ] = useState<boolean>(false);
	const [ errors, setErrors ] = useState<any>({});
	const [ passwordStrength, setPasswordStrength ] = useState<number>(0);
	const [ validated, setValidated ] = useState<boolean>(false);

	useEffect(() => {
		if (!isReady) {
			if (props.type === 'edit') {
				let userTmp = props.user;
				userTmp['password'] = '';
				setUser(props.user);
				setIsReady(true);
			} else {
				setUser({
					first_name: '',
					last_name: '',
					email: '',
					qualtrics_id: '',
					school: '',
					cohort: '',
					is_coach: false,
					is_admin: false,
					password: ''
				});
				setIsReady(true);
			}
		}
	});

	const handleTextEntry = (event: any) => {
		let data = { 
			[event.target.id]: event.target.value 
		};
		setUser({...user, ...data});
	}

	const handleCheckbox = (event: any) => {
		let key = event.target.id;
		let data = { 
			[key]: !user[key]
		};
		setUser({...user, ...data});
	}

	const handleSubmit = (event:any) => {
		const form = event.currentTarget;
		event.preventDefault();
		event.stopPropagation();
		setValidated(true);
	    if (form.checkValidity() === true && passwordIsStrong() ) {
	    	if ( props.type === 'edit' ) {
				updateExistingUser();
			} else {
				createNewUser();
			}
	    }
	}

	const passwordIsStrong = () => {
		if (user.password === '') {
			return true;
		} else {
			if ( passwordStrength > 2 ) {
				return true;
			}
			setErrors({...errors, ...{password: 'Please set a stronger password.'}})
			return false;
		}
	}

	const updateExistingUser = () => {
		setIsProcessing(true);
		updateUser(props.user.id, user).then( resp => {
			toast('User updated successfully');
			setIsProcessing(false);
			props.handleFormSubmit(true);
		}).catch( err => {
			console.log(err);
			toast('There was an error');
			setIsProcessing(false);
		})
	}

	const createNewUser = () => {
		setIsProcessing(true);
		createUser(user).then( resp => {
			toast('User created successfully');
			setIsProcessing(false);
			props.handleFormSubmit(true);
		}).catch( err => {
			console.log(err);
			toast('There was an error');
			setIsProcessing(false);
		})
	}

	const handleChangeScore = (event:any) => {
		setPasswordStrength(event);
	}
	
	return (
		isReady ?
		<Form noValidate validated={validated} onSubmit={handleSubmit}>
        	<Form.Group className="mb-3">
				<Form.Label>First Name</Form.Label>
				<Form.Control type="text" id="first_name" value={user.first_name} onChange={handleTextEntry} required />
				{ errors.first_name ? <span className="validation-error">{errors.first_name}</span> : '' }
			</Form.Group>
			<Form.Group className="mb-3">
				<Form.Label>Last Name</Form.Label>
				<Form.Control type="text" id="last_name" value={user.last_name} onChange={handleTextEntry} required />
				{ errors.last_name ? <span className="validation-error">{errors.last_name}</span> : '' }
			</Form.Group>
			<Form.Group className="mb-3">
				<Form.Label>Email</Form.Label>
				<Form.Control type="email" id="email" value={user.email} onChange={handleTextEntry} required />
				<Form.Text className="text-muted">Must match email in Qualtrics.</Form.Text>
				{ errors.email ? <span className="validation-error">{errors.email}</span> : '' }
			</Form.Group>
			<Form.Group className="mb-3">
				<Form.Label>Password</Form.Label>
				<Form.Control type="text" id="password" value={user.password} onChange={handleTextEntry} isInvalid={errors.password} required={props.type !== 'edit'} />
				<div className="password-meter">
					<PasswordStrengthBar password={user.password} onChangeScore={handleChangeScore} />
				</div>
				{ errors.password ? <span className="validation-error">{errors.password}</span> : '' }
			</Form.Group>
			<Form.Group className="mb-3">
				<Form.Label>Qualtrics ID</Form.Label>
				<Form.Control type="text" id="qualtrics_id" value={user.qualtrics_id} onChange={handleTextEntry} required />
				<Form.Text className="text-muted">Must match recipient ID in Qualtrics.</Form.Text>
				{ errors.qualtrics_id ? <span className="validation-error">{errors.qualtrics_id}</span> : '' }
			</Form.Group>
			<Form.Group className="mb-3">
				<Form.Label>School</Form.Label>
				<Form.Control type="text" id="school" value={user.school} onChange={handleTextEntry} />
				{ errors.school ? <span className="validation-error">{errors.school}</span> : '' }
			</Form.Group>
			<Form.Group className="mb-3">
				<Form.Label>Cohort</Form.Label>
				<Form.Control type="text" id="cohort" value={user.cohort} onChange={handleTextEntry} />
				{ errors.cohort ? <span className="validation-error">{errors.cohort}</span> : '' }
			</Form.Group>
			<Form.Group className="mb-4">
				<Form.Check type="switch" id="is_coach" label="Maximize Team Member" value={user.is_coach} onChange={handleCheckbox} checked={user.is_coach} />
			</Form.Group>
			<Form.Group className="mb-4">
				<Form.Check type="switch" id="is_admin" label="Administrator" value={user.is_admin} onChange={handleCheckbox} checked={user.is_admin} />
			</Form.Group>
			<Form.Group className="mb-3">
		        <Button variant="primary" size="lg" type="submit">Save</Button>
		    </Form.Group>
		</Form>
		:
		<div>Loading...</div>
	)
}