import React, { useState, useEffect } from "react";

import { parseISO, format } from 'date-fns';

import { ReactComponent as CheckIcon } from '../../images/icons/check.svg';
import { ReactComponent as CheckIconEmpty } from '../../images/icons/check-empty.svg';

export default function UserGoalCard(props: any) {

	const [showDescription, setShowDescription] = useState<boolean>(false);


	return (
		<div className="user-goal-card panel mb-4">

			<div className="d-flex align-items-center w-100">
				<div className="goal-statement-container">
					<span className="goal-statement">
						<h3>{props.goal.statement ? props.goal.statement : props.goal.description}</h3>
						{showDescription &&
							<p>{props.goal.description}</p>
						}
						<button className="btn btn-text pt-0" onClick={() => setShowDescription(!showDescription)}>{showDescription ? 'Hide' : 'Show'} SMARTIE Steps</button>
						<div className="goal-metadata">
							<div className="reviews d-flex mb-2">
								<span>Goal Reviewed:</span>
								{props.goal.review_count > 0 ?
									<CheckIcon />
									:
									<CheckIconEmpty />
								}
								{props.goal.review_count > 1 ?
									<CheckIcon />
									:
									<CheckIconEmpty />
								}
							</div>

							<div className="duration d-flex mb-2">
								<span>Last Review:&nbsp;</span>
								{props.goal.reviewed_at ? format(parseISO(props.goal.reviewed_at), 'MMM d, yyyy') : ''}
							</div>
							<div className="duration d-flex">
								<span>Duration:&nbsp;</span>
								{format(parseISO(props.goal.created_at), 'MMM d, yyyy')}

								{
									props.goal.is_complete ?
										' - ' + format(parseISO(props.goal.updated_at), 'MMM d, yyyy')
										:
										' - ' + format(new Date(), 'MMM d, yyyy')
								}

							</div>

						</div>
					</span>
				</div>
			</div>

		</div>
	)
}