import React, { useState, useEffect } from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Button } from "react-bootstrap";
import { toast } from 'react-toastify';

import { resetUser } from '../../_services/user';
import { generateScoreSet } from '../../_services/strategy-score';

export default function AdminStuff(props: any) {

    const handleResetUser = () => {
        if (window.confirm('Are you sure you want to reset this user? This action is permanent and cannot be undone.')) {
            resetUser(props.user.id).then((response: any) => {
                toast.success('User has been reset');
                //window.location.reload();
                props.reload(true);
            }).catch((error: any) => {
                toast.error('There was an error resetting the user');
            });
        }
    }

    const handleGenerateScores = () => {
        generateScoreSet(props.user.id).then((response: any) => {
            toast.success('Scores have been generated');
            //window.location.reload();
            props.reload(true);
        }).catch((error: any) => {
            toast.error('There was an error generating scores');
        });
    }

    return (

        <Row className="mb-5">
            <Col xs={12}>
                <h2>Admin Stuff</h2>
                <div className="panel mb-5">
                    <Row>
                        <Col xs={12} md={6}>
                            <p className="mb-0"><strong>Email:</strong> {props.user.email}</p>
                            <p className="mb-0"><strong>School:</strong> {props.user.school ? props.user.school : '-'}</p>
                            <p className="mb-0"><strong>Cohort:</strong> {props.user.cohort ? props.user.cohort : '-'}</p>
                            <p className="mb-0"><strong>Research Consent:</strong> {props.user.consents[0] && props.user.consents[0].status === 1 ? 'Yes' : 'No'}</p>
                        </Col>
                        <Col xs={12} md={6}>
                            <p className="mb-0"><strong>Admin User:</strong> {props.user.is_admin ? 'Yes' : 'No'}</p>
                            <p className="mb-0"><strong>Team Lead:</strong> {props.user.is_coach ? 'Yes' : 'No'}</p>
                            <p className="mb-0"><strong>Logins:</strong> {props.user.login_count}</p>
                        </Col>
                    </Row>
                </div>

                <h2>Generate Strategy Scores</h2>
                <div className="panel mb-5">
                    <p>This will regenerate strategy scores from the user's latest self-assessment. If they have not completed a self-assessment, then it will do nothing.</p>
                    <Button variant="primary" onClick={handleGenerateScores} >Generate Scores</Button>
                </div>
                

                <h2 className="text-danger">Reset User</h2>
                <div className="panel border-danger">
                    <p><strong>CAUTION!!</strong> This will wipe all data from the user account, including workflows, goals, self-assessments, reflections, meetings, observations and research consents. This is meant for testing and development use only.</p>
                    <Button variant="danger" onClick={handleResetUser} >Reset User</Button>
                </div>
            </Col>
        </Row>
    );
}