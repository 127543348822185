import React, {useState, useEffect} from "react";
import { toast } from 'react-toastify';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import {Link} from "react-router-dom";
import UserForm from './user-form';
import { deleteUser } from '../../_services/user';

export default function DeleteUser( props: any ) {

	const [ showModal, setShowModal ] = useState<boolean>(false);

	const handleModalOpen = () => {
		setShowModal(true);
	}

	const handleModalClose = () => {
		setShowModal(false);
	}

	const handleReload = () => {
		props.handleReload(true);
	}

	const deleteSelectedUser = () => {
		deleteUser( props.user.id ).then( resp => {
			toast('User deleted successfully');
			props.handleReload(true);
			setShowModal(false);
		}).catch( err => {
			toast('There was an error');
			console.log(err);
		});
	}

	return (
		<>
			<Button variant="danger" size="sm" onClick={handleModalOpen}>Delete</Button>
			<Modal show={showModal} onHide={handleModalClose}>
		        <Modal.Header closeButton>
		          <Modal.Title>Delete user</Modal.Title>
		        </Modal.Header>
		        <Modal.Body>
		        	<p>Are you sure you would like to delete this user? This action is permanent.</p>
		        	<Button variant="danger" onClick={deleteSelectedUser}>Delete</Button>
		        </Modal.Body>
		      </Modal>
		</>
	)
}