import { get, post } from '../xhr';

export function login(request:any) {
	let url = 'auth/login';
	return post(url, request);
}

export function requestPasswordReset(request:any) {
	let url = 'auth/request-password-reset';
	return post(url, request);
}

export function resetPassword(request:any) {
	let url = 'auth/password/recovery';
	return post(url, request);
}

export function logOut() {
	let url = 'auth/logout';
	let request = {};
	return post(url, request);
}