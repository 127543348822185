import React, {useState, useEffect} from "react";
import Table from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown'
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import {Link} from "react-router-dom";
import { toast } from 'react-toastify';
import { parseISO, format } from 'date-fns';
import 'react-toastify/dist/ReactToastify.css';
import { deleteObservation } from '../../_services/observation';


export default function ObservationRow( props: any ) {

    const [ showModal, setShowModal ] = useState<boolean>(false);
    const [ strategyList, setStrategyList ] = useState<string>('');

    useEffect(() => {
        if ( props.observation.observation_strategies !== undefined && props.observation.observation_strategies.length > 0 ) {
            buildStrategyList();
        }
    }, [props]);

    const buildStrategyList = () => {
        let list = '';
        props.observation.observation_strategies.forEach((strategy: any, index: number) => {
            if ( index > 0 ) {
                list += ', ';
            }
            list += strategy.strategy.name;
        });
        setStrategyList(list);
    }
    const remove = () => {
        deleteObservation(props.observation.id).then( resp => {
            toast('Observation deleted');
            props.handleReload(true);
            handleModalClose();
        }).catch( err => {
            console.log(err);
            toast('Unauthorized action');
        });
    }

    const handleModalOpen = () => {
		setShowModal(true);
	}

	const handleModalClose = () => {
		setShowModal(false);
	}

	return (
		<tr>
            <td>{props.observation.date}</td>
            <td>{strategyList}</td>
            <td>
                <div className="d-flex align-items-center">
                    <Button variant="secondary" size="sm" className="me-2" href={'/observation-details?observationId=' + props.observation.id}>View</Button>
                    <Button variant="danger" size="sm" onClick={handleModalOpen}>Delete</Button>
                </div>
            </td>
            <Modal show={showModal} onHide={handleModalClose}>
                <Modal.Header closeButton>
                <Modal.Title>Delete observation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you would like to delete this observation?</p>
                    <div className="d-flex align-items-center">
                        <Button variant="secondary" className="me-2" onClick={handleModalClose}>Cancel</Button>
                        <Button variant="danger" onClick={remove}>Delete</Button>
                    </div>
                </Modal.Body>
            </Modal>
        </tr>
	)
}