import Axios from 'axios';

function returnAxiosInstance() {

	let initializers = {
		baseURL: process.env.REACT_APP_API_URL,
		headers: {
			'Authorization': 'Bearer ' + localStorage.getItem('token')!
		}
	};

	var axiosInstance = Axios.create(initializers);

	axiosInstance.interceptors.response.use( (res) => {
		return res;
	}, (err) => {
		if ( err.response.status === 401 ) {
			window.location.href = '/login';
		}
		return Promise.reject(err);
	});
	
  	return axiosInstance;
}

export function get(url: string){
	const axios = returnAxiosInstance();
	return axios.get(url);
} 

export function post(url: string, request: any){
	const axios = returnAxiosInstance();
	return axios.post(url, request);
} 

export function put(url: string, request: any){
	const axios = returnAxiosInstance();
	return axios.put(url, request);
} 

export function destroy(url: string) {
	const axios = returnAxiosInstance();
	return axios.delete(url);
} 
