import React from "react";
import { Outlet } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default function FocusLayout( props: any ) {
	return (
		<Container className="focus-layout">
			<Row className="justify-content-center">
			{
				props.size === 'sm' &&
					<Col xs={12} md={8} lg={6} xl={5} className="mt-4" >
						<Outlet />
					</Col>
			}
			{
				props.size === 'med' &&
					<Col xs={12} md={12} lg={9} xl={7} className="mt-4" >
						<Outlet />
					</Col>
			}	
			{
				props.size === 'lg' &&
					<Col xs={12} md={12} lg={12} xl={12} className="mt-4" >
						<Outlet />
					</Col>
			}	
			</Row>
		</Container>
	)
}