import React, { useState, useEffect } from "react";
import ProgressBar from 'react-bootstrap/ProgressBar';
import Placeholder from 'react-bootstrap/Placeholder';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getSurvey, startSession } from '../../_services/survey';
import Session from './session';

export default function Survey(props: any) {

	const navigate = useNavigate();
	const [survey, setSurvey] = useState<any>();
	const [session, setSession] = useState<any>();
	const [ready, setReady] = useState(false);
	const [hasErrors, setHasErrors] = useState(false);
	const [progress, setProgress] = useState(10);
	const [showProgressBar, setShowProgressBar] = useState(true);
	const [backButtonPressed, setBackButtonPressed] = useState<boolean>(false);

	useEffect(() => {
		if (!ready) {
			getSurveyDetails();
		}
		// window.addEventListener("beforeunload", handleBeforeUnload);
		// // window.addEventListener("popstate", handleBackButton);

		// return () => {
		// 	window.removeEventListener("beforeunload", handleBeforeUnload);
		// 	// window.addEventListener("popstate", handleBackButton);
		// };
	});

	// const handleBackButton = (e:any) => {
	// 	console.log('handleBackButton');
	// 	console.log(e);
	// 	e.preventDefault();
	// 	if ( window.confirm('You sure?') ) {
	// 		navigate("/dashboard", {replace: true});
	// 	}
	// }

	const handleBeforeUnload = (e: any) => {
		console.log('handleBeforeUnload');
		e.preventDefault();
		const message = "Are you sure you want to reload this page? All data will be lost.";
		e.returnValue = message;
		return message;
	}

	const getSurveyDetails = () => {
		// why hide progress bar for these surveys?
		if (
			props.surveyId === 'SV_exlVuBwRNz1m11A' ||
			props.surveyId === 'SV_6qXNmqudcy14JpQ' ||
			props.surveyId === 'SV_9MQ9x3JJbM9UAey' ||
			props.surveyId === 'SV_6S5udObUtb4l5Ua' ||
			props.surveyId === 'SV_7UOdoBCbSpngmx0' ||
			props.surveyId === 'SV_0qBUwjgCtkCmNAW' ||
			props.surveyId === 'SV_9AAFseWYQ3ZV9KS' ||
			props.surveyId === 'SV_bmBvJzQGeA27mke' ||
			props.surveyId === 'SV_esNl6FGBa8GYHrM' ||
			props.surveyId === 'SV_cwiGr37bA9PczPg'
		) {
			setShowProgressBar(false);
		}

		getSurvey(props.surveyId).then(resp => {
			setSurvey(resp.data.data);
			setReady(true);
			document.title = resp.data.data.name + ' | Maximize Project';
		}, err => {
			toast('Failed to load survey');
			navigate('../dashboard', { replace: true });
		});
	}

	const updateProgress = (val: number) => {
		setProgress(val);
	}

	return (
		(ready && !hasErrors) ?
			<div className="survey">
				<div className="survey-header">
						<svg onClick={() => navigate('/workflow-routing')} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
							<path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
						</svg>
					<div className="progress-container">
						{showProgressBar ? <ProgressBar now={progress} /> : ''}
						<h1>{survey.name}</h1>
					</div>
				</div>
				<div className="session">
					<Session survey={survey} progress={updateProgress} showProgressBar={showProgressBar} goal={props.goal} />
				</div>
			</div>
			:
			<div className="survey">
				<div className="survey-header">
					<Placeholder animation="glow">
						<Placeholder as={ProgressBar} xs={12} />
					</Placeholder>
				</div>
			</div>
	)


}
