import { get, post, put, destroy } from '../xhr';

export function getAllSurveysInProgress() {
	let url = 'survey-in-progress';
	return get(url);
}

export function getSurveyInProgressBySurveyId(surveyId:string) {
	let url = 'survey-in-progress/survey/' + surveyId;
	return get(url);
}

export function createSurveyInProgress(request:any) {
	let url = 'survey-in-progress';
	return post(url, request);
}

export function updateSurveyInProgress(id:number, request:any) {
	let url = 'survey-in-progress/' + id;
	return put(url, request);
}

export function deleteSurveyInProgress(id:number) {
	let url = 'survey-in-progress/' + id;
	return destroy(url);
}