import React, { useState, useEffect } from "react";
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import { Link } from "react-router-dom";
import UserResult from './user-result';


export default function UserResultsTable(props: any) {

	const handleReload = () => {
		props.handleReload(true);
	}

	const user = JSON.parse(localStorage.getItem('user')!);

	let results = props.results.data.map(function (item: any, index: number) {
		return <UserResult key={index} result={item} handleReload={handleReload} />
	});

	return (
		<div>
			<Table striped bordered hover responsive className="results-table">
				<thead>
					<tr>
						<td>ID</td>
						<td>Name</td>
						<td>Email</td>
						<td>School</td>
						{
							user.is_admin ?
								<>
									<td>Cohort</td>
									<td>MTM</td>
									<td>Admin</td>
									
								</>
								:
								<>
								</>
						}
						{
							user.is_coach ?
								<>
									<td>Invites</td>
								</>
								:
								<></>
						}
						{
							user.is_admin ?
								<td>&nbsp;</td>
								:
								<></>
						}

					</tr>
				</thead>
				<tbody>
					{results}
				</tbody>
			</Table>
		</div>
	)
}