import React, { useState, useEffect } from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Placeholder from 'react-bootstrap/Placeholder';

import { Link } from "react-router-dom";
import GoalCard from './goal-card';
import { getGoals } from '../../_services/goal';

import { ReactComponent as CaretIcon } from '../../images/caret.svg';

export default function MyGoals(props: any) {

	const [goalElements, setGoalElements] = useState<any>();
	const [completedGoalElements, setCompletedGoalElements] = useState<any>();
	const [archivedGoalElements, setArchivedGoalElements] = useState<any>();
	const [goals, setGoals] = useState<any>();
	const [archiveOpen, setArchiveOpen] = useState<boolean>(false);


	useEffect(() => {
		getMyGoals();
	}, []);

	useEffect(() => {
		if (goals) {
			buildGoalList();
		}
	}, [goals]);

	const getMyGoals = () => {
		let request = {
			is_complete: true,
			is_archived: false
		}
		getGoals(request).then((resp: any) => {
			setGoals(resp.data.data);
			console.log(resp.data.data);
		});
	}

	const buildGoalList = () => {
		if (goals.length > 0) {
			var elements = goals.filter((item: any) => {
				return item.strategy.id === props.strategyId && item.is_complete;
			}).map(function (item: any, index: number) {
				return <GoalCard key={index} goal={item} reloadGoals={handleReload} />
			});
			setCompletedGoalElements(elements);

			var elements = goals.filter((item: any) => {
				return item.strategy.id === props.strategyId && item.is_archived;
			}).map(function (item: any, index: number) {
				return <GoalCard key={index} goal={item} reloadGoals={handleReload} />
			});
			setArchivedGoalElements(elements);

			var elements = goals.filter((item: any) => {
				return item.strategy.id === props.strategyId && !item.is_complete && !item.is_archived;
			}).map(function (item: any, index: number) {
				return <GoalCard key={index} goal={item} reloadGoals={handleReload} />
			});
			setGoalElements(elements);
		} else {
			setGoalElements({});
		}
	}

	const handleReload = () => {
		props.reload(true);
	}

	return (
		<>

			{goalElements ?
				<>
					<h3>Current Goal</h3>
					<div className="goal-container mb-4">
						<Row>
							<Col>
								{goalElements.length > 0 ?
									goalElements
									:
									<span>You do not have a current goal for this strategy. Continue along your path to create one.</span>
								}
							</Col>
						</Row>
					</div>
				</>
				:
				<div className="placeholder-container placeholder-glow">
					<Placeholder as="h1" xs={8} style={{ height: '20px', marginBottom: '16px' }} />
					<Placeholder as="div" xs={12} style={{ height: '120px' }} />
				</div>
			}

			{completedGoalElements && completedGoalElements.length > 0 ?
				<>
					<h3>Completed Goals</h3>
					<div className="goal-container">
						<Row>
							<Col>
								{completedGoalElements}
							</Col>
						</Row>
					</div>
				</>
				:
				<></>
			}

			{archivedGoalElements && archivedGoalElements.length > 0 ?
				<div>
					<h4 onClick={() => setArchiveOpen(!archiveOpen)}>
						{
							archiveOpen ?
								<CaretIcon style={{ transform: 'rotateZ(90deg)', marginTop: '-2px'}} />
								:
								<CaretIcon style={{ transform: 'rotateZ(0deg)', marginTop: '-2px'}} />
						}
						<span>Archived Goals</span>
					</h4>
					<div className={'goal-container ' + (archiveOpen ? 'd-block' : 'd-none')}>
						<Row>
							<Col>
								{archivedGoalElements}
							</Col>
						</Row>
					</div>

				</div>
				:
				<></>
			}





		</>
	)
}