import { get, post, put, destroy } from '../xhr';

export function getObservationById(id: number) {
	let url = 'observation/' + id;
	return get(url);
}

export function getObservationsByTeacher(id: number) {
	let url = 'observation/teacher/' + id;
	return get(url);
}

export function createObservation( request: any ) {
	let url = 'observation';
	return post(url, request);
}

export function updateObservation( id: any, request: any ) {
	let url = 'observation/' + id;
	return put(url, request);
}

export function deleteObservation( id: any ) {
	let url = 'observation/' + id;
	return destroy(url);
}