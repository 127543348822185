import React, {useState, useEffect} from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import {Link} from "react-router-dom";
import UserForm from './user-form';

export default function EditUser( props: any ) {

	const [ showModal, setShowModal ] = useState<boolean>(false);

	const handleModalOpen = () => {
		setShowModal(true);
	}

	const handleModalClose = () => {
		setShowModal(false);
	}

	const handleFormSubmit = () => {
		setShowModal(false);
		props.handleReload(true);
	}

	return (
		<>
			<Button variant="secondary" size="sm" onClick={handleModalOpen}>Edit</Button>
			<Modal show={showModal} onHide={handleModalClose}>
		        <Modal.Header closeButton>
		          <Modal.Title>Edit user</Modal.Title>
		        </Modal.Header>
		        <Modal.Body>
		        	<UserForm type={'edit'} user={props.user} handleFormSubmit={handleFormSubmit} />
		        </Modal.Body>
		      </Modal>	
		</>
	)
}