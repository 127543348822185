import React, { useState, useEffect } from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Profile from '../components/profile';
import { set } from "date-fns";

export default function MyProfilePage(props: any) {

	const [userId, setUserId] = useState<any>();

	useEffect(() => {
		setUserId(JSON.parse(localStorage.getItem('user')!).id);
	}, []);



	return (
		<div>
			<Row>
				<Col xs={12}>
					{userId && <Profile userId={userId} />}
				</Col>
			</Row>
		</div>
	)
}
