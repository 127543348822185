import { get, post } from '../xhr';

export function getSurvey(id: string) {
	let url = 'survey/' + id;
	return get(url);
}

export function startSession(surveyId: string) {
	let url = 'survey/' + surveyId + '/start';
	let qualtrics_id = JSON.parse(localStorage.getItem('user')!).qualtrics_id;
	let request = {
    	"language": "EN",
    	"recipientId": qualtrics_id
	};
	return post(url, request);
}

export function getSession(sessionId: string, surveyId: string) {
	let url = 'survey/' + surveyId + '/' + sessionId;
	return get(url);
}

export function updateSession(sessionId: string, surveyId: string, request: any) {
	let url = 'survey/' + surveyId + '/' + sessionId;
	// console.log('update session xhr req:', request);
	return post(url, request);
}