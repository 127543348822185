import React, { useState, useEffect } from "react";
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link, useParams } from "react-router-dom";

import StrategyGameboard from '../../components/strategy-gameboard';

export default function StrategyWorkflowPage(props: any) {

    document.title = 'Strategy Workflow | Maximize Project';

    let { strategyId } = useParams();

    useEffect(() => {
        // get current workflow
        // get current strategy
    }, []);

    useEffect(() => {
        console.log('strategyId', strategyId);
    }, [strategyId]);

    return (
        <div>
            <StrategyGameboard strategyId={strategyId} />
        </div>
    )
}
