import React, { useState, useEffect } from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Placeholder from 'react-bootstrap/Placeholder';
import Modal from 'react-bootstrap/Modal';

import { format } from 'date-fns';
import { toast } from 'react-toastify';

import { getUser } from '../../_services/user';
import { generateCertificate } from '../../_services/report';
import { getAllCoachTeacherEntries } from '../../_services/coach-teacher';
import { approveEntry, deleteEntry } from '../../_services/coach-teacher';

import StrategyChart from './strategy-chart';
import StrategyTable from '../strategy-table';
import UserGoals from '../user-goals';
import Meetings from '../meetings';
import Observations from '../observations';
import MyCoaches from '../my-coaches';
import Stats from './stats';
import AdminStuff from './admin-stuff';

export default function Profile(props: any) {


  const [isReady, setIsReady] = useState<any>(false);
  const [certificateProcessing, setCertificateProcessing] = useState<boolean>(false);

  const [user, setUser] = useState<any>(null);
  const [coachTeacherEntries, setCoachTeacherEntries] = useState<any>([]);
  const [coachRequestElements, setCoachRequestElements] = useState<any>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showCoachingUI, setShowCoachingUI] = useState<boolean>(false);

  let userJSON = localStorage.getItem('user');
  let authUser = userJSON !== null ? JSON.parse(userJSON) : {};
  let isAdmin = authUser !== null ? authUser.is_admin : false;
  let isCoach = authUser !== null ? authUser.is_coach : false;

  useEffect(() => {
    getUserData();
    getCoachRequests();
  }, []);

  useEffect(() => {
    if (user !== null) {
      //console.log('user', user);
      checkConnections();
    }
  }, [user]);

  useEffect(() => {
    if (coachTeacherEntries.length > 0) {
      buildCoachRequestElements();
    } else {
      setShowModal(false);
    }
  }, [coachTeacherEntries]);

  const checkConnections = () => {
    if (user.coach_teacher_entries.length > 0) {
      user.coach_teacher_entries.forEach((item: any) => {
        if (item.is_approved && item.coach_id === authUser.id) {
          setShowCoachingUI(true);
        }
      });
    } else {
      setShowCoachingUI(false);
    }
  }

  const buildCoachRequestElements = () => {
    let el = coachTeacherEntries.map((entry: any, index: number) => {
      return (
        <div className="mb-2" key={index}>
          <span className="goal-statement">
            <h5> {entry.coach.first_name} {entry.coach.last_name} would like to connect with you.</h5>
            <p className="mb-0">This will allow {entry.coach.first_name} to review your active and completed goals in Maximize. You can revoke this access at any time.</p>
          </span>
          <div className="d-flex justify-content-between mt-3">
            <Button variant={'danger'} className="me-2" onClick={() => handleDeny(entry.id)}>Deny</Button>
            <Button variant={'primary'} onClick={() => handleApprove(entry.id)}>Approve</Button>
          </div>
        </div>
      )
    });
    setCoachRequestElements(el);
    setShowModal(true);
  }

  const handleApprove = (id: number) => {
    let request = {
      is_approved: true
    }
    approveEntry(id, request).then(resp => {
      removeCoachRequestEntry(id);
    }).catch(err => {
      console.log(err);
    });
  }

  const handleDeny = (id: number) => {
    deleteEntry(id).then(resp => {
      removeCoachRequestEntry(id);
    }).catch(err => {
      console.log(err);
    });
  }

  const removeCoachRequestEntry = (id: number) => {
    let entries = coachTeacherEntries.filter((entry: any) => {
      return entry.id !== id;
    });
    setCoachTeacherEntries(entries);
  }

  const handleModalOpen = () => {
    setShowModal(true);
  }

  const handleModalClose = () => {
    setShowModal(false);
  }


  const getUserData = () => {
    getUser(props.userId).then((resp: any) => {
      setUser(resp.data.data);
    }).catch(err => {
      console.log(err);
    });
  }

  const getCoachRequests = () => {
    getAllCoachTeacherEntries('teacher').then(resp => {
      let arr: any = [];
      resp.data.data.forEach((item: any) => {
        if (!item.is_approved) {
          arr.push(item);
        }
      });
      setCoachTeacherEntries(arr);
    }).catch(err => {
      console.log(err);
    });
  }

  const handleGenerateCertificate = () => {
    setCertificateProcessing(true);
    generateCertificate().then(resp => {
      toast.success(resp.data.message);
      setCertificateProcessing(false);
    }).catch(err => {
      console.log(err);
      toast.error('There was an error generating your certificate.');
      setCertificateProcessing(false);
    });
  }

  const triggerCoachesReload = () => {
    getUserData();
  }

  return (
    <>

      <div className="profile-container">
        <Row>
          <Col xs={12}>
            <div className="
              d-flex 
              flex-column
              align-items-start 
              justify-content-start 
              flex-sm-row
              align-items-sm-center 
              justify-content-sm-between 
              mb-5
              ">
              {
                user ?
                  <div className="mb-4 mb-md-0">
                    <h1 className="mb-0">{user.first_name} {user.last_name}</h1>
                    <span>Joined {format(user.created_at, 'MMM yyyy')}</span>
                  </div>
                  :
                  <Placeholder as="div" className="profile-header-placeholder" size="lg" />
              }

              <Button variant="primary" onClick={handleGenerateCertificate}>{certificateProcessing ? 'Processing...' : 'Generate Certificate'}</Button>
            </div>
          </Col>
        </Row>

        {user ?
          <Stats user={user} />
          :
          <Placeholder as="div" className="panel-placeholder" size="lg" />
        }

        {user && authUser.id === user.id ?
          <MyCoaches reload={triggerCoachesReload} />
          :
          <></>
        }

        {user && (authUser.id === user.id || authUser.is_admin) && user.strategy_scores.length > 0 ?
          <Row>
            <Col xs={12} className="mb-5">
              <h2>Self-assessments</h2>
              {
                user ?
                  <div className="panel">
                    <StrategyChart user={user} />
                  </div>
                  :
                  <Placeholder as="div" className="panel-placeholder" size="lg" />
              }
            </Col>
            <Col xs={12}>
              <StrategyTable scores={user.strategy_scores} />
            </Col>
          </Row>
          :
          <>  </>
        }

        {user ?
          <>
            <Row className="mb-4">
              <Col xs={12}>
                <h2>Active Goals</h2>
                <div>
                  <UserGoals userId={user.id} type={"active"} />
                </div>
              </Col>
            </Row>

            <Row className="mb-4">
              <Col xs={12}>
                <h2>Completed Goals</h2>
                <div>
                  <UserGoals userId={user.id} type={"completed"} />
                </div>
              </Col>
            </Row>
          </>

          :
          <></>
        }


        {
          showCoachingUI ?
            <>
              <Row className="mb-5">
                <Col xs={12}>
                  <h2 className="d-flex justify-content-between">
                    Meetings
                    <Button variant="secondary" size="sm" href={'/meeting-details?teacherId=' + props.userId}>+ New meeting</Button>
                  </h2>
                  <div className="panel">
                    <Meetings userId={props.userId} />
                  </div>
                </Col>
              </Row>

              <Row className="mb-5">
                <Col xs={12}>
                  <h2 className="d-flex justify-content-between">
                    Observations
                    <Button variant="secondary" size="sm" href={'/observation-details?teacherId=' + props.userId}>+ New observation</Button>
                  </h2>
                  <div className="panel">
                    <Observations userId={props.userId} />
                  </div>
                </Col>
              </Row>
            </>
            :
            <></>
        }

        {user && authUser.is_admin ?
          <AdminStuff user={user} reload={getUserData} />
          :
          <></>
        }

      </div >

      <Modal show={showModal} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Connection Requests</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {coachRequestElements}
        </Modal.Body>
      </Modal>

    </>
  );
}