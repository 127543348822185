import { get, post } from '../xhr';

export function getStrategyScores() {
	let url = 'strategy-score';
	return get(url);
}

export function generateScoreSet(userId: number) {
	let url = 'strategy-score/generate/' + userId;
	return get(url);
}