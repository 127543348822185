import React, { useState, useEffect } from "react";
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Loader from '../loader';
import { getObservationsByTeacher } from '../../_services/observation';
import { ElementFlags } from "typescript";
import ObservationRow from './observation-row';

export default function Observations(props: any) {

    const [elements, setElements] = useState<any>();
    const [isReady, setIsReady] = useState<boolean>(false);

    useEffect(() => {
        getObservations();
    }, []);

    const getObservations = () => {
        getObservationsByTeacher(props.userId).then(resp => {
            buildElements(resp.data.data);
        }).catch(err => {
            console.log(err);
        });
    }

    const buildElements = (data: any) => {
        let el: any = [];
        if (data.length > 0) {
            data.forEach((item: any, index: number) => {
                el.push(<ObservationRow key={index} observation={item} handleReload={getObservations} />);
            });
        }
        setElements(el);
        setIsReady(true);
    }

    return (
        isReady ?
            <div className="meetings-container">
                {
                    elements.length > 0 ?
                        <Table>
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Strategy</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {elements}
                            </tbody>
                        </Table>
                        :
                        <p className="m-0">You have no observations with this user.</p>
                }
            </div>
            :
            <></>
    )
}

