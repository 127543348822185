import React, {useState, useEffect} from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import {Link} from "react-router-dom";
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import PasswordStrengthBar from 'react-password-strength-bar';
import { resetPassword } from '../_services/auth';
import {ReactComponent as Logo} from '../images/maximize-logo.svg';

export default function PasswordResetPage( props: any ) {

	document.title = 'Reset you password | Maximize Project';

	let { token } = useParams();
	let navigate = useNavigate();
	const [ isProcessing, setIsProcessing ] = useState<boolean>(false);
	const [ email, setEmail ] = useState<string>('');
	const [ newPassword, setNewPassword ] = useState<string>('');
	const [ confirmPassword, setConfirmPassword ] = useState<string>('');
	const [ emailErr, setEmailErr ] = useState<any>();
	const [ newPwErr, setNewPwErr ] = useState<any>();
	const [ confirmPwErr, setConfirmPwErr ] = useState<any>();
	const [ isValid, setIsValid ] = useState<boolean>(false);
	const [ passwordStrength, setPasswordStrength ] = useState<number>(0);
	const [ validated, setValidated ] = useState<boolean>(false);

	useEffect(() => {handleSubmit()}, [isValid])

	const handleTextEntry = (event: any) => {
		let id = event.target.id;
		let value = event.target.value;
		if ( id === 'email' ) {
			setEmail(value);
		} else if ( id === 'new-password' ) {
			setNewPassword(value);
		} else if ( id === 'confirm-password' ) {
			setConfirmPassword(value);
		}
	}

	const handleSubmit = () => {
		if ( isValid ) {
			attemptRequest();
		}
	}

	const validateForm = () => {
		setEmailErr('');
		setConfirmPwErr('');
		setNewPwErr('');
		if ( email.length === 0 ) {
			setEmailErr(<span className="validation-error">This field is required.</span>);
			setIsValid(false);
		} else
		if ( newPassword.length === 0 ) {
			setNewPwErr(<span className="validation-error">This field is required.</span>);
			setIsValid(false);
		} else
		if ( passwordStrength < 3 ) {
			setNewPwErr(<span className="validation-error">Please choose a stronger password.</span>);
			setIsValid(false);
		} else
		if ( confirmPassword.length === 0 ) {
			setConfirmPwErr(<span className="validation-error">This field is required.</span>);
			setIsValid(false);
		} else
		if ( newPassword !== confirmPassword ) {
			setConfirmPwErr(<span className="validation-error">Password fields must match.</span>);
			setIsValid(false);
		} else {
			setIsValid(true);
		}
	}

	const attemptRequest = () => {
		setIsProcessing(true);
	    let request = {
	      token: token,
	      newPassword: newPassword,
	      confirmPassword: confirmPassword
	    }
	    resetPassword(request).then( (resp:any) => {
	    	toast('New password saved!');
	    	setIsProcessing(false);
	      	navigate("../login", { replace: true });
	    }).catch( (err:any) => {
	      console.log(err);
	      toast('Failed to save new password');
	      setIsProcessing(false);
	    });
	  }

	const handleChangeScore = (event:any) => {
		setPasswordStrength(event);
	}

	const passwordIsStrong = () => {
		if ( passwordStrength > 2 ) {
			return true;
		}
		return false;
	}

	return (
		<div className="panel">
			<Logo id="logo" />
			<div className="login-form">
		      <Form noValidate>
		        <Form.Group className="mb-3" controlId="email">
		          <Form.Control type="email" placeholder="Enter email" required onChange={handleTextEntry} />
		          {emailErr}
		        </Form.Group>
		        <Form.Group className="mb-3" controlId="new-password">
		          	<Form.Control type="password" placeholder="New password" required onChange={handleTextEntry} />
		         	 <div className="password-meter">
						<PasswordStrengthBar password={newPassword} onChangeScore={handleChangeScore} />
					</div>
		          	{newPwErr}
		        </Form.Group>
		        <Form.Group className="mb-4" controlId="confirm-password">
		          <Form.Control type="password" placeholder="Confirm new password" required onChange={handleTextEntry} />
		          {confirmPwErr}
		        </Form.Group>
		        <Button variant="primary" size="lg" className="mb-4 mr-0" onClick={validateForm}>
		          { isProcessing ? 'Processing...' : 'Save' }
		        </Button>
		        <Link to="/login" className="text-center">Don't need this? Sign in</Link>
		      </Form>
		    </div>
		</div>
	)
}