import React, { useState, useEffect } from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import StrategyCard from './strategy-card';
import { getStrategyScores } from '../../_services/strategy-score';
import { updateAssessment } from '../../_services/self-assessment';

export default function StrategyTable(props: any) {

	const [col1, setCol1] = useState<any>();
	const [col2, setCol2] = useState<any>();
	const [col3, setCol3] = useState<any>();
	const [col4, setCol4] = useState<any>();
	const [empty, setEmpty] = useState<boolean>(true);

	useEffect(() => {
		buildScoreTable();
	}, []);

	const buildScoreTable = () => {


		// get most recent set of scores from props.scores
		let currentScores = props.scores.slice(0, 10);
		let lastScores = props.scores.slice(10, 20);
		//let scores = props.scores.length > 0 ? props.scores : [];
		let lastScore: any = null;

		if (currentScores.length === 0) {
			setEmpty(true);
		} else {
			let elements = [];
			elements = currentScores.filter(function (item: any) {
				return item.score === 0;
			}).map(function (item: any, index: number) {
				lastScore = lastScores.length > 0 ? lastScores[index].score : null;
				return <StrategyCard key={index} strategy={item.strategy} currentScore={item.score} lastScore={lastScore} />
			});
			setCol1(elements);

			elements = currentScores.filter(function (item: any) {
				return item.score === 1;
			}).map(function (item: any, index: number) {
				lastScore = lastScores.length > 0 ? lastScores[index].score : null;
				return <StrategyCard key={index} strategy={item.strategy} currentScore={item.score} lastScore={lastScore} />
			});
			setCol2(elements);

			elements = currentScores.filter(function (item: any) {
				return item.score === 2;
			}).map(function (item: any, index: number) {
				lastScore = lastScores.length > 0 ? lastScores[index].score : null;
				return <StrategyCard key={index} strategy={item.strategy} currentScore={item.score} lastScore={lastScore} />
			});
			setCol3(elements);

			elements = currentScores.filter(function (item: any) {
				return item.score === 3;
			}).map(function (item: any, index: number) {
				lastScore = lastScores.length > 0 ? lastScores[index].score : null;
				return <StrategyCard key={index} strategy={item.strategy} currentScore={item.score} lastScore={lastScore} />
			});
			setCol4(elements);

			setEmpty(false);
		}
	}

	return (
		<>
			{
				empty ?
					<></>
					:
					<div className="strategy-container">
						<h2>My Strategy Profile</h2>
						<p className="mb-5">Below are the results from your most recent self-assessment activity. If you see a green up arrow, that indicates that this strategy has moved up a category since your last self-assessment.</p>
						<div className="strategy-table">
							<Row>
								<Col xs={12} md={6} xl={3} className="mb-5">
									<h4>Area for Growth</h4>
									<div>
										{col1.length > 0 ? col1 : <span>No strategies in this category</span>}
									</div>
								</Col>
								<Col xs={12} md={6} xl={3} className="mb-5">
									<h4>Developing</h4>
									<div>
										{col2.length > 0 ? col2 : <span>No strategies in this category</span>}
									</div>
								</Col>
								<Col xs={12} md={6} xl={3} className="mb-5">
									<h4>Skilled</h4>
									<div>
										{col3.length > 0 ? col3 : <span>No strategies in this category</span>}
									</div>
								</Col>
								<Col xs={12} md={6} xl={3} className="mb-5">
									<h4>Accomplished</h4>
									<div>
										{col4.length > 0 ? col4 : <span>No strategies in this category</span>}
									</div>
								</Col>
							</Row>
						</div>
					</div>
			}
		</>
	)
}