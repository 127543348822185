import React, {useState, useEffect} from "react";
import FormCheck from 'react-bootstrap/FormCheck';

export default function Choice( props: any ) {
	const [ isReady, setIsReady ] = useState<any>(false);
	const [ isEmpty, setIsEmpty ] = useState(false);
	const [ choiceData, setChoiceData ] = useState<any>();
	let choiceClass = 'choice choice_' + props.sequence;

	useEffect(() => {
		if ( !isReady ) {
			setChoiceData({
				display: props.choice.display,
				value: props.choice.choiceId,
				sequence: props.sequence
			});
			if ( props.choice.display.length === 0 ) {
				setIsEmpty(true);
			}
			setIsReady(true);
		}
	});

	const handleChange = (e:any) => {
		props.isChecked(choiceData);
	}

	return (
		isReady && !isEmpty ?
		<div className={choiceClass}>
			<FormCheck 
		        type="radio"
		        id={props.questionId + '_' + props.choice.choiceId}
		        value={props.choice.choiceId}
		        label={props.choice.display}
		        name={props.questionId}
		        onChange={handleChange}
		      />
		</div>
		:
		<div></div>
	)
}