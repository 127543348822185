import { get, post, put, destroy } from '../xhr';

export function getAllUserWorkflows() {
	let url = 'user-workflow/all';
	return get(url);
}

export function getMyUserWorkflows() {
	let url = 'user-workflow';
	return get(url);
}

export function getMyActiveUserWorkflow() {
	let url = 'user-workflow/active';
	return get(url);
}

export function findUserWorkflowById(id: number) {
	let url = 'user-workflow/' + id;
	return get(url);
}

export function createUserWorkflow(request:any) {
	let url = 'user-workflow';
	return post(url, request);
}

export function updateUserWorkflow(id:number, request:any) {
	let url = 'user-workflow/' + id;
	return put(url, request);
}

export function deleteUserWorkflow(id:number) {
	let url = 'user-workflow/' + id;
	return destroy(url);
}