import React from 'react';
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactGA from "react-ga4";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';

import { ProtectedRoute } from './components/auth/protected-route';
import FocusLayout from './components/layout/focus-layout';
import StandardLayout from './components/layout/standard-layout';
import StandardFluidLayout from './components/layout/standard-fluid-layout';
import SurveyLayout from './components/layout/survey-layout';
import MainNav from './components/main-nav';
import Dashboard from './pages/dashboard';
import SurveyPage from './pages/survey-page';
import WelcomePage from './pages/welcome-page';
import LoginPage from './pages/login-page';
import LogoutPage from './pages/logout-page';
import UsersPage from './pages/users-page';
import UserProfilePage from './pages/user-profile-page';
import ForgotPasswordPage from './pages/forgot-password-page';
import PasswordResetPage from './pages/password-reset-page';
import StrategiesPage from './pages/strategies-page';
import ReflectionsPage from './pages/reflections-page';
import AgreeToTermsPage from './pages/agree-to-terms-page';
import UserSettingsPage from './pages/user-settings-page';
import MeetingPage from './pages/meeting-page';
import ObservationPage from './pages/observation-page';
import ReportsPage from './pages/reports-page';
import WorkflowRoutingPage from './pages/workflow-routing-page';
// onboarding workflow pages
import OnboardingWelcomePage from './pages/onboarding-workflow/onboarding-welcome-page';
import OnboardingReflectionPage from './pages/onboarding-workflow/onboarding-reflection-page';
// main workflow pages
import SelfAssessmentPage from './pages/main-workflow/self-assessment-page';
import ChooseStrategyPage from './pages/main-workflow/choose-strategy-page';
// strategy workflow pages
import StrategyWorkflowPage from './pages/strategy-workflow/strategy-workflow-page';
import MyProfilePage from './pages/my-profile-page';

import NotFoundPage from './pages/not-found-page';

function App() {

  ReactGA.initialize("G-QCJWR6E61Q");
  ReactGA.send("pageview");

  return (
    <div className="App">
      <ToastContainer />
      <header className="App-header">
        <Routes>

          {/* Public routes */}
          <Route path="/" element={<FocusLayout size="sm" />}>
            <Route path="" element={<LoginPage />} />
            <Route path="login" element={<LoginPage />} />
            <Route path="logout" element={<LogoutPage />} />
            <Route path="forgot-password" element={<ForgotPasswordPage />} />
            <Route path="reset-password/:token" element={<PasswordResetPage />} />
            <Route path="*" element={<NotFoundPage />} />
          </Route>

          <Route path="/" element={<FocusLayout size="lg" />}>

            <Route path="agree-to-terms" element={<ProtectedRoute component={AgreeToTermsPage} page="terms" />} />

            {/* Onboarding workflow routes */}
            <Route path="onboarding-welcome" element={<ProtectedRoute component={OnboardingWelcomePage} />} />
            <Route path="onboarding-reflection/:survey_id" element={<ProtectedRoute component={OnboardingReflectionPage} />} />

            {/* Main workflow routes */}
            <Route path="self-assessment" element={<ProtectedRoute component={SelfAssessmentPage} />} />
            <Route path="choose-strategy" element={<ProtectedRoute component={ChooseStrategyPage} />} />
            
            {/* Survey routes */}
            <Route path="survey/:surveyId" element={<ProtectedRoute component={SurveyPage} />} />
            
          </Route>

          <Route path="/" element={<StandardLayout />}>

            <Route path="strategy-workflow/:strategyId" element={<ProtectedRoute component={StrategyWorkflowPage} />} />

            <Route path="my-profile" element={<ProtectedRoute component={MyProfilePage} />} />

            <Route path="users/:id" element={<ProtectedRoute component={UserProfilePage} />} />
            <Route path="meeting-details" element={<ProtectedRoute component={MeetingPage} />} />
            <Route path="observation-details" element={<ProtectedRoute component={ObservationPage} />} />

            <Route path="strategies" element={<ProtectedRoute component={StrategiesPage} />} />
            <Route path="reflections" element={<ProtectedRoute component={ReflectionsPage} />} />
            <Route path="reports" element={<ProtectedRoute component={ReportsPage} page="reports" />} />
            <Route path="settings" element={<ProtectedRoute component={UserSettingsPage} />} />
 
            <Route path="workflow-routing" element={<ProtectedRoute component={WorkflowRoutingPage} />} />

          </Route>

          <Route path="/" element={<StandardFluidLayout />}>
            <Route path="users" element={<ProtectedRoute component={UsersPage} />} />
          </Route>

        </Routes>
      </header>
      <div id="footer">
        <div id="copyright">Copyright © 2022 Ohio University. All Rights Reserved.</div>
      </div>
    </div>
  );
}

export default App;
