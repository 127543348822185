import { get, put } from '../xhr';

export function getStrategies() {
	let url = 'strategy';
	return get(url);
}

export function updateStrategy( id: any, request: any ) {
	let url = 'strategy/' + id;
	return put(url, request);
}