import React, {useState, useEffect} from "react";
import { useSearchParams, useNavigate } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import {Link} from "react-router-dom";
import Profile from '../components/profile';
import ObservationForm from '../components/observations/observation-form';

export default function ObservationPage( props: any ) {
	
	const [ searchParams ] = useSearchParams();
	const teacherId = searchParams.get('teacherId');
	const observationId = searchParams.get('observationId');
	const [ onSubmit, setOnSubmit ] = useState<boolean>(false);

	useEffect(() => {

	}, []);

	const handleSubmit = (event: any) => {
	}

	return (
		<div>
			<Row>
				<Col xs={12}>
                    <h1 className="mb-0">Observation Details</h1>
                    <p className="mb-4">For privacy reasons, please don't include names in observation notes.</p>
                    <Card className="p-4">
                        <Card.Body>
                            <ObservationForm teacherId={teacherId} observationId={observationId} />
                        </Card.Body>
                    </Card>
				</Col>
			</Row>
		</div>
	)
}
