import React, {useState, useEffect} from "react";
import Button from 'react-bootstrap/Button';
import {Link} from "react-router-dom";
import {ReactComponent as Logo} from '../images/maximize-logo.svg';
import RequestPasswordResetForm from "../components/auth/request-password-reset-form";

export default function ForgotPasswordPage( props: any ) {

	document.title = 'Forgot your password? | Maximize Project';

	return (
		<div className="panel">
			<Logo id="logo" />
			<RequestPasswordResetForm />
		</div>
	)
}