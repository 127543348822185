import React, { useState, useEffect } from "react";
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from "react-router-dom";
import { getAllCoachTeacherEntries } from '../../_services/coach-teacher';
import Coach from './coach';

export default function MyCoaches(props: any) {

    const [results, setResults] = useState<any>();

    useEffect(() => {
        getConnections();
    }, [props.reload]);

    const getConnections = () => {
        getAllCoachTeacherEntries('teacher').then(resp => {
            buildResults(resp.data.data);
        }).catch(err => {
            console.log('getConnections', err);
        });
    }

    const handleReload = () => {
        getConnections();
        //props.handleReload(true);
    }

    const buildResults = (data: any) => {
        let elements: any = [];
        let count = 0;

        data.forEach((item: any, index: number) => {
            if (item.is_approved) {
                elements.push(<Coach key={index} result={item} handleReload={handleReload} />);
                count++;
            }
        });

        if (count > 0) {
            setResults(elements);
        } else {
            setResults([]);
        }
    }

    return (
        results && results.length > 0 ?

            <Row className="mb-5">
                <Col xs={12}>
                    <h2>My Connections</h2>
                    <div>
                        {results}
                    </div>
                </Col>
            </Row>

            :
            <></>

    )
}