import React, { useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Placeholder from 'react-bootstrap/Placeholder';

import Fireworks from "react-canvas-confetti/dist/presets/fireworks";


import { getMyUserWorkflows } from '../../_services/user-workflow';
import GameboardStep from './gameboard-step';
import GameboardHeader from './gameboard-header';
import GameboardIcon from './gameboard-icon';

import MyGoals from '../../components/my-goals';
import { getMyReflections } from '../../_services/user-reflection';
import { getAllReflections } from '../../_services/reflection';
import { getGoals } from '../../_services/goal';
import { handleStepCompletion } from '../../components/user-workflow/user-workflow-controller';

import PlantGraphic from '../../images/gameboard/plant.png';
import SunGraphic from '../../images/gameboard/sun.png';

export default function StrategyGameboard(props: any) {

    const [workflow, setWorkflow] = useState<any>();
    const [userReflections, setUserReflections] = useState<any>();
    const [reflections, setReflections] = useState<any>();
    const [nextReflection, setNextReflection] = useState<any>();
    const [goal, setGoal] = useState<any>();
    const [showModal, setShowModal] = useState<boolean>(false);


    useEffect(() => {
        getReflections();
        getUserReflections();
        getWorkflow();
    }, []);

    useEffect(() => {
        console.log('workflow', workflow);
        if (workflow) {
            getMyGoals();
        }

        if (
            workflow &&
            workflow.workflow.name === 'Strategy' &&
            workflow.current_step === workflow.workflow.steps.length &&
            workflow.current_step_status === 'complete' &&
            workflow.is_complete === 0
        ) {
            setShowModal(true);
        }
    }, [workflow]);

    const getMyGoals = () => {
        getGoals().then(resp => {
            resp.data.data.forEach((goal: any) => {
                if (goal.strategy_id === workflow.strategy.strategy.id) {
                    setGoal(goal);
                }
            });
        });
    }

    const getReflections = () => {
        getAllReflections().then((response: any) => {
            setReflections(response.data.data);
        }).catch(error => {
            console.error('error', error);
        });
    }

    const getUserReflections = () => {
        getMyReflections().then((response: any) => {
            setUserReflections(response.data.data);
        }).catch(error => {
            console.error('error', error);
        });
    }

    const getWorkflow = () => {
        getMyUserWorkflows().then((response: any) => {
            let activeWorkflow = response.data.data.find((workflow: any) => (workflow.is_complete === 0 && workflow.is_active === 1));
            setWorkflow(activeWorkflow);
        }).catch(error => {
            console.error('error', error);
        });
    };

    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);

    const handleClick = () => {
        handleStepCompletion({
            workflowName: 'Strategy'
        });
    }

    return (
        <>

            <Row id="gameboard" className={'justify-content-center ' + (workflow && workflow.strategy ? workflow.strategy.strategy.color : 'yellow')}>
                <Col xs={12} md={7} lg={8} className="justify-content-center position-relative test">
                    {
                        workflow
                            ?
                            <>
                                <GameboardHeader strategyName={workflow.strategy.strategy.name} />
                                <div className="gameboard-steps">
                                    {
                                        workflow.workflow.steps.map((step: any, index: number) => {
                                            return <GameboardStep
                                                key={index}
                                                step={step}
                                                workflow={workflow}
                                                goal={goal}
                                            />
                                        })
                                    }
                                    <GameboardStep step={{ name: 'Finish' }} workflow={workflow} />
                                </div>
                                {/* <img src={PlantGraphic} alt="Plant" className="plant" />
                                <img src={SunGraphic} alt="Sun" className="sun" /> */}
                            </>
                            :
                            <div className="placeholder-container placeholder-glow">
                                <Placeholder as="h1" xs={12} style={{ height: '50px', marginBottom: '16px' }} />
                                <Placeholder as="div" xs={12} style={{ height: '420px', marginBottom: '16px' }} />
                            </div>
                    }
                </Col>

                {workflow ?
                    <Col xs={12} md={5} lg={4}>
                        <MyGoals strategyId={workflow.strategy.strategy.id} />
                    </Col>
                    :
                    <Col xs={12} md={5} lg={4}>
                        <div className="placeholder-container placeholder-glow">
                                <Placeholder as="h1" xs={8} style={{ height: '20px', marginBottom: '16px' }} />
                                <Placeholder as="div" xs={12} style={{ height: '120px' }} />
                        </div>
                    </Col>
                }
            </Row>

            <Modal centered show={showModal} onHide={handleClose}>
                <Fireworks autorun={{ speed: 3, duration: 600, delay: 500 }} />
                <Modal.Body className="gameboard-modal justify-content-center d-flex flex-column align-items-center">
                    <GameboardIcon name={'trophy'} isComplete={true} />
                    <h2>All done! Nice work!</h2>
                    <p className="text-center mb-4">You completed all steps for this strategy! Now it's time to do another self-assessment, and choose a new strategy to work on.</p>
                    <Button variant="primary" size="lg" onClick={handleClick}>Continue</Button>
                </Modal.Body>
            </Modal>
        </>
    )
}