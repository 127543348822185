import React, { useState, useEffect } from "react";
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown'
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import { parseISO, format, differenceInDays, differenceInWeeks } from 'date-fns';
import 'react-toastify/dist/ReactToastify.css';

import { updateGoal, deleteGoal } from '../../_services/goal';
import { resetCurrentWorkflow } from '../user-workflow/user-workflow-controller';


import { ReactComponent as CheckIcon } from '../../images/icons/check.svg';
import { ReactComponent as CheckIconEmpty } from '../../images/icons/check-empty.svg';

export default function GoalCard(props: any) {

	const [ready, setReady] = useState<boolean>(false);
	const [showModal, setShowModal] = useState<boolean>(false);
	const [modalType, setModalType] = useState<string>('');
	const [goalStatement, setGoalStatement] = useState<any>(props.goal.statement);
	const [goalDescription, setGoalDescription] = useState<any>(props.goal.description);
	const [goalDuration, setGoalDuration] = useState<any>();
	const [showDescription, setShowDescription] = useState<boolean>(false);

	useEffect(() => {
		if (!ready) {
			calculateGoalDuration();
		}
	});

	const handleModalOpen = (event: any) => {
		setModalType(event.target.innerText);
		setShowModal(true);
	}

	const handleModalClose = () => {
		setShowModal(false);
	}

	const handleDelete = () => {
		deleteGoal(props.goal.id).then((resp: any) => {
			props.reloadGoals(true);
		}).catch((err: any) => {
			toast.error('Failed to delete');
			console.log(err);
		});
	}

	const handleEdit = () => {
		if (goalStatement.length === 0) {
			toast.error('Goal Statement is required');
			return false;
		}
		if (goalDescription.length === 0) {
			toast.error('SMARTie Steps are required');
			return false;
		}
		updateGoal(props.goal.id, { description: goalDescription, statement: goalStatement }).then((resp: any) => {
			props.reloadGoals(true);
			toast('Changes saved successfully');
		}).catch((err: any) => {
			toast.error('Failed to save');
			console.log(err);
		});
	}

	const handleComplete = () => {
		updateGoal(props.goal.id, { is_complete: true }).then((resp: any) => {
			props.reloadGoals(true);
			toast('Goal marked as complete');
		}).catch((err: any) => {
			toast.error('Failed to mark as complete');
			console.log(err);
		});
	}

	const handleArchive = () => {

		updateGoal(props.goal.id, { is_archived: true }).then((resp: any) => {
			resetCurrentWorkflow();
			toast.success('Goal archived');
		}).catch((err: any) => {
			toast.error('Failed to archive');
			console.log(err);
		});

	}

	const handleReinstate = () => {
		updateGoal(props.goal.id, { is_archived: false, is_complete: false }).then((resp: any) => {
			props.reloadGoals(true);
			toast('Goal reinstated');
		}).catch((err: any) => {
			toast.error('Failed to reinstate');
			console.log(err);
		});
	}

	const handleTextEntry = (event: any) => {
		if (event.target.id === 'goalStatement') {
			setGoalStatement(event.target.value);
		} else {
			setGoalDescription(event.target.value);
		}
	}

	const calculateGoalDuration = () => {
		let today = new Date();
		let start = parseISO(props.goal.created_at);
		setGoalDuration(differenceInWeeks(today, start));
		setReady(true);
	}

	return (
		<div className="goal-card">
			<div className="d-flex flex-column align-items-start justify-content-start w-100">
				<div className="goal-statement-container">
					<span className="goal-statement">
						<h3>{props.goal.statement == null ? props.goal.description : props.goal.statement}</h3>
						{showDescription &&
							<p>{props.goal.description}</p>
						}
						<button className="btn btn-text" onClick={() => setShowDescription(!showDescription)}>{showDescription ? 'Hide' : 'Show'} SMARTIE Steps</button>
						<div className="goal-metadata d-flex align-items-center justify-content-between">
							<div className="d-flex flex-column align-items-start justify-content-start">
								<div className="reviews mb-2">
									<span className="me-2">Reviewed:</span>
									{props.goal.review_count > 0 ?
										<CheckIcon />
										:
										<CheckIconEmpty />
									}
									<div className="me-2"></div>
									{props.goal.review_count > 1 ?
										<CheckIcon />
										:
										<CheckIconEmpty />
									}
								</div>
								<div className="duration">
									{
										goalDuration > 4 ?
											<span>Duration: {goalDuration} weeks</span>
											:
											<span>Duration: {goalDuration}/4 weeks</span>
									}
								</div>
							</div>
							{!props.goal.is_complete && !props.goal.is_archived &&
								<Dropdown>
									<Dropdown.Toggle className="btn btn-secondary" id="dropdown-basic"></Dropdown.Toggle>
									<Dropdown.Menu>
										{/* <Dropdown.Item><Link to={'/survey/' + process.env.REACT_APP_GOAL_REVIEW_SURVEY_ID} state={{ 'goal': props.goal }}>Review</Link></Dropdown.Item> */}
										<Dropdown.Item onClick={handleModalOpen}>Edit</Dropdown.Item>
										<Dropdown.Item onClick={handleModalOpen}>Archive</Dropdown.Item>
									</Dropdown.Menu>
								</Dropdown>
							}


						</div>
					</span>
				</div>
			</div>
			<Modal show={showModal} onHide={handleModalClose}>
				{
					modalType === 'Complete' &&
					<div>
						<Modal.Header closeButton>
							<Modal.Title>Great job on this goal!</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<p>You will recieve education credits for your hard work! You can review your completed goals on the Achievements page.</p>
							<p>Ready to mark it as complete?</p>
							<div className="d-flex mt-4">
								<Button variant="secondary" onClick={handleModalClose}>Keep working</Button>
								<Button variant="primary" onClick={handleComplete}>Mark as complete</Button>
							</div>
						</Modal.Body>
					</div>
				}
				{
					modalType === 'Edit' &&
					<div>
						<Modal.Header closeButton>
							<Modal.Title>Edit goal</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<label className="fw-bold mb-2">Goal Statement</label>
							<textarea id="goalStatement" value={goalStatement} onChange={handleTextEntry} rows={4} className="form-control mb-4"></textarea>
							<label className="fw-bold mb-2">SMARTIE Steps</label>
							<textarea id="goalDescription" value={goalDescription} onChange={handleTextEntry} rows={4} className="form-control mb-5"></textarea>
							<Button variant="primary" onClick={handleEdit}>Save</Button>
						</Modal.Body>
					</div>
				}
				{
					modalType === 'Archive' &&
					<div>
						<Modal.Header closeButton>
							<Modal.Title>Archive goal</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<p className="mb-4">Are you sure you would like to archive this goal?</p>
							<div className="d-flex mt-4">
								<Button variant="secondary" onClick={handleModalClose}>Keep working</Button>
								<Button variant="primary" onClick={handleArchive}>Archive</Button>
							</div>
						</Modal.Body>
					</div>
				}

			</Modal>
		</div>
	)
}