import React, { useState, useEffect } from 'react';
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Button from 'react-bootstrap/Button';


import { ReactComponent as BookIcon } from '../../images/gameboard/book.svg';
import { ReactComponent as StarIcon } from '../../images/gameboard/star.svg';
import { ReactComponent as LightbulbIcon } from '../../images/gameboard/lightbulb.svg';
import { ReactComponent as CheckIcon } from '../../images/gameboard/check.svg';
import { ReactComponent as GlassIcon } from '../../images/gameboard/magnifying-glass.svg';

import { ReactComponent as TrophyIconDefault } from '../../images/gameboard/trophy-default.svg';
import { ReactComponent as TrophyIconActive } from '../../images/gameboard/trophy-active.svg';




export default function GameboardIcon(props: any) {

    const [icon, setIcon] = useState<any>();

    useEffect(() => {
        buildIcon();
    }, []);

    const buildIcon = () => {
        switch (props.name) {
            case 'book':
                setIcon(<BookIcon />);
                break;
            case 'star':
                setIcon(<StarIcon />);
                break;
            case 'glass':
                setIcon(<GlassIcon />);
                break;
            case 'check':
                setIcon(<CheckIcon />);
                break;
            case 'lightbulb':
                setIcon(<LightbulbIcon />);
                break;
            case 'trophy':
                if (props.isComplete) {
                    setIcon(<TrophyIconActive />);
                } else {
                    setIcon(<TrophyIconDefault />);
                }
                break;
            default:
                setIcon(<StarIcon />);
                break;
        }
    }

    return (
        <>
            {props.name == 'trophy' ?
                <div className="gameboard-icon trophy-icon">
                    {icon}
                </div>
                :
                <button className={'gameboard-icon ' + (props.isComplete ? 'complete' : '')}>{icon}</button>
            }
        </>

    );
}