import { get, post, put, destroy } from '../xhr';

export function getAllCoachTeacherEntries(type: string) {
	let url = 'coach-teacher?type=' + type;
	return get(url);
}

export function getEntry(id: number) {
	let url = 'coach-teacher/' + id;
	return get(url);
}

export function createEntry( request: any ) {
	let url = 'coach-teacher';
	return post(url, request);
}

export function approveEntry( id: number, request: any ) {
	let url = 'coach-teacher/' + id;
	return put(url, request);
}

export function deleteEntry( id: any ) {
	let url = 'coach-teacher/' + id;
	return destroy(url);
}