import React, { useState, useEffect } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { updateStrategy } from "../../_services/strategy";
import { getAllReflections } from "../../_services/reflection";

export default function EditStrategy(props: any) {

    const [showModal, setShowModal] = useState<boolean>(false);
    const [strategy, setStrategy] = useState<any>({});
    const [reflections, setReflections] = useState<any>([]);

    useEffect(() => {
        setStrategy({
            id: props.strategy.id,
            name: props.strategy.name,
            page_id: props.strategy.page_id,
            reflection_1: props.strategy.reflections.find((r: any) => r.order === 1)?.reflection.id,
            reflection_2: props.strategy.reflections.find((r: any) => r.order === 2)?.reflection.id
        });
        getAllReflections().then((resp: any) => {
            setReflections(resp.data.data);
        });
	}, []);

    const handleModalOpen = () => {
        setShowModal(true);
    }

    const handleModalClose = () => {
        setShowModal(false);
    }

    const handleTextEntry = (event: any) => {
		let data = { 
			[event.target.id]: event.target.value 
		};
		setStrategy({...strategy, ...data});
	}

    const handleSelectChange = (event: any) => {
        let data = { 
            [event.target.id]: event.target.value 
        };
        setStrategy({...strategy, ...data});
	}

	const handleSubmit = () => {
		updateStrategy(strategy.id, strategy).then((resp: any) => {
            handleModalClose();
        });
	}

    return (
        <>
            <Button variant="secondary" className="ms-2" onClick={handleModalOpen}>Edit</Button>
            <Modal show={showModal} onHide={handleModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Strategy</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" id="name" value={strategy.name} onChange={handleTextEntry} disabled />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Page URL</Form.Label>
                            <Form.Control type="text" id="page_id" value={strategy.page_id} onChange={handleTextEntry} required />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Reflection 1</Form.Label>
                            <Form.Select id="reflection_1" aria-label="Choose a reflection..." value={strategy.reflection_1} onChange={handleSelectChange} required>
                                <option value="">Choose a reflection...</option>
                                {reflections.map((reflection: any) => {
                                    return <option key={reflection.id} value={reflection.id}>{reflection.name}</option>
                                })}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Reflection 2</Form.Label>
                            <Form.Select id="reflection_2" aria-label="Choose a reflection..." value={strategy.reflection_2} onChange={handleSelectChange} required>
                                <option value="">Choose a reflection...</option>
                                {reflections.map((reflection: any) => {
                                    return <option key={reflection.id} value={reflection.id}>{reflection.name}</option>
                                })}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3 mt-4">
                            <Button variant="primary" size="lg" onClick={handleSubmit}>Save</Button>
                        </Form.Group>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    )
}