import React, { useState } from "react";
import { Link } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { login } from '../../_services/auth';
import { useKeyPress } from '../../_services/key-press';
import { ReactComponent as LockIcon } from '../../images/lock-icon.svg';

const LoginForm = () => {

  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [validated, setValidated] = useState<boolean>(false);

  let navigate = useNavigate();

  const handleSubmit = (event: any) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    setValidated(true);
    if (form.checkValidity() === true) {
      attemptLogin();
    }
  }

  const attemptLogin = () => {
    setIsProcessing(true);
    let creds = {
      email: email,
      password: password
    }
    login(creds).then((resp: any) => {
      let user = resp.data.data.user;
      localStorage.setItem('token', resp.data.data.access_token);
      localStorage.setItem('user', JSON.stringify(user));
      setIsProcessing(false);

      if (user.research_consent != null) {
        navigate('../workflow-routing', { replace: true });
      } else {
        navigate('../agree-to-terms', { replace: true });
      }

    }).catch((err: any) => {
      toast.error('Login failed');
      setIsProcessing(false);
    });
  }

  const handleTextEntry = (event: any) => {
    let id = event.target.id;
    let value = event.target.value;
    if (id === 'email') {
      setEmail(value);
    } else if (id === 'password') {
      setPassword(value);
    }
  }

  return (
    <div className="login-form">
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="email">
          <Form.Control type="email" placeholder="Enter email" onChange={handleTextEntry} required />
        </Form.Group>
        <Form.Group className="mb-3" controlId="password">
          <Form.Control type="password" placeholder="Password" onChange={handleTextEntry} required />
        </Form.Group>
        <Link to="/forgot-password" className="mb-2">Forgot your password?</Link><br />
        <Button variant="primary" size="lg" type="submit" >
          Sign In
        </Button>
      </Form>
    </div>
  );
};

export default LoginForm;