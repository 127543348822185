import React, {useState, useEffect} from 'react';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel';
import FormControl from 'react-bootstrap/FormControl';
import MultipleChoice from './multiple-choice';

export default function Question(props: any) {

	let choices;
	let questionClass = 'question question_' + props.sequence;

	const handleChecked = (choice: any) => {
		let resp = {
			[props.question.questionId]: {
				[choice.value]: {
					selected: true
				}
			}
		}
		props.questionResponse(resp, choice, props.question, props.sequence);
	}

	const handleText = (event: any) => {
		let resp = {
			[props.question.questionId]: event.target.value
		}
		let choice = {};
		props.questionResponse(resp, choice, props.question, props.sequence);
	}

	switch (props.question.type) {
		case 'mc':
			choices = <MultipleChoice choices={props.question.choices} questionId={props.question.questionId} selectedItem={handleChecked} />
			break;
		case 'te':
			choices = <textarea className="form-control" rows={4} onChange={handleText} id={props.question.questionId} name={props.question.questionId}></textarea>;
			//choices = <FormControl type="text" onChange={handleText} id={props.question.questionId} name={props.question.questionId} />;
			break;
		default:
			choices = null;
			break;
	}

	let error;
	if (props.message) {
		error = <span className="error">{props.message}</span>;
	}

	return (
		<div className={questionClass} id={'question_'+props.sequence}>
			<FormGroup className={`${error ? "has-error" : ""}`}>
				{error}
  				<FormLabel><span dangerouslySetInnerHTML={{__html: props.question.display}}></span></FormLabel>
				{choices}
			</FormGroup>
		</div>
	)

}